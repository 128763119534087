<template>
  <div v-if="displayFilters.length">
    <component :is="componentTag" :visible="openSidebar" class="custom-sidebar-filters" title="" :overlay="true"
      :button="false" @close="$emit('closeSideBar')">
      <div class="filters desktop-only" v-if="updatedFilters "><!-- && widgetConfig.showProductFilter -->
        <div v-for="oFilter in displayFilters" :key="oFilter?.sName">
          <div v-if="oFilter" :class="{'d-none': oFilter.hasDefaultFilter}">
            <!-- :class="{'d-none': oFilter.hasDefaultFilter}" 'display-none': hiddenFilterList.includes(oFilter.sName) -->
            <SfHeading :level="4" :title="truncateString(t(getFilterName(oFilter.sName)), 18)" class="filters__title sf-heading--left"
              v-bind:class="{ 'sub_child_filter': oFilter.isSubChild, 'child_filter': oFilter.isChild, 'd-none': oFilter.sName == 'COLOR' }"
              v-if="oFilter.aOptions.length && !oFilter.hideFilter"/>
            <div v-bind:class="{ 'sub_child_filter': oFilter.isSubChild, 'child_filter': oFilter.isChild }" v-if="oFilter.aOptions.length && !oFilter.hideFilter">
              <div v-if="oFilter?.showSearchBy">
                <SfInput :placeholder="t('SEARCH')" v-model="oFilter.searchTerm"/>
              </div>
              <div v-if="oFilter.sInputGroup == 'checkbox' && !oFilter.bIsDynamic"
                class="filter-option-section max-filter-section" :ref="'element-' + oFilter.sName">
                <div v-for="oOption in filterOptions(oFilter)" :key="oOption.value">
                  <!--  -->
                  <SfFilter :label="t(oOption.value )" :count="displayOptionLabel(oOption)" :selected="oOption?.selected"
                    class="filters__item" :class="{ 'd-none': displayOptionLabel(oOption) == 0 }"
                    @change="onChangeFilter($event, oOption, oFilter)">
                    <template #label="{ label }">
                      <div class="sf-filter__label dmNewParagraph">
                        <p class="my-0">{{ truncateString(label, 20) }}</p>
                      </div>
                    </template>
                    <template #count="{ count }">
                      <div class="sf-filter__count dmNewParagraph">
                        <p class="my-0">{{ count }}</p>
                      </div>
                    </template>
                  </SfFilter>
                </div>
              </div>
              <div v-if="showApiFields && oFilter.sInputGroup == 'checkbox' && oFilter.bIsDynamic"
                class="filter-option-section max-filter-section" :ref="'element-' + oFilter.sName">
                <SfFilter v-for="oOption in filterOptions(oFilter)" :key="oOption._id" :label="t(oOption.label || '')"
                  :count="displayOptionLabel(oOption)" :selected="oOption?.selected" class="filters__item"
                  :class="{ 'd-none': displayOptionLabel(oOption) == 0 }"
                  @change="onChangeFilter($event, oOption, oFilter)">
                  <template #label="{ label }">
                    <div class="sf-filter__label dmNewParagraph">
                      <p class="my-0">{{ truncateString(label, 20) }}</p>
                    </div>
                  </template>
                  <template #count="{ count }">
                    <div class="sf-filter__count dmNewParagraph">
                      <p class="my-0">{{ count }}</p>
                    </div>
                  </template>
                </SfFilter>
              </div>
              <div v-if="oFilter.sInputGroup == 'radio' && !oFilter.bIsDynamic"
                class="filter-option-section max-filter-section" :ref="'element-' + oFilter.sName">
                <SfRadio v-for="oOption in oFilter.aOptions" :key="oOption.value" :label="t(oOption.value)"
                  :count="displayOptionLabel(oOption)" :value="oOption.value" :active="oFilter?.selected"
                  class="filters__item" :class="{ 'd-none': displayOptionLabel(oOption) == 0 }"
                  @change="onChangeFilter($event, oOption, oFilter)">
                  <template #label="{ label }">
                    <div class="sf-filter__label dmNewParagraph">
                      <p class="my-0">{{ label }}</p>
                    </div>
                  </template>
                  <template #count="{ count }">
                    <div class="sf-filter__count dmNewParagraph">
                      <p class="my-0">{{ count }}</p>
                    </div>
                  </template>
                </SfRadio>
              </div>
              <!-- <div
                v-if="oFilter.sInputGroup == 'checkboxWithColor' && !oFilter.bEnableOptionGroup && oFilter.aSubGroupIds?.length" class="filter-option-section max-filter-section">
                <template v-for="oSubGroup in oFilter.aSubGroupIds">
                  <span  v-if="oSubGroup" :key="oSubGroup?.sName" :class="{ 'd-none': oSubGroup.isHideTag }">
                    <SfHeading :level="5" :title="getTranslatedLabel(oSubGroup.sName)" class="sf-heading--left" />
                    <div class="filters__colors">
                      <div class="d-flex" v-for="oColorOption in oSubGroup.aOptions" :key="oSubGroup.sName + oColorOption.value">
                        <SfColor
                          :color="oColorOption.hex" :selected="isColorSelected(oColorOption)" class="filters__color"
                          @click="onChangeFilter($event, oColorOption, oSubGroup)"/>
                          <p class="filters__color_label"> 
                            <span>{{ t(oColorOption.value) }}</span>  
                            <span v-if="oColorOption.count">{{ oColorOption.count }}</span>
                            </p>
                      </div>
                    </div>
                  </span>
                </template>
              </div> -->
              <div v-if="oFilter.sInputGroup == 'slider'">
                <div class="price-slider" v-if="oFilter.sName == 'PRICE' && oFilter.selected?.length > 1">
                  <div class="min-price"> 
                    <div>
                      <SfInput type="number" :placeholder="t('MIN_TEXT')" v-model="oFilter.selected[0]"
                        @change="onChangeFilter(oFilter.selected, null, oFilter)" />
                    </div>
                    <div>{{ t('MIN_PRICE_RANGE') }}</div>
                  </div>
                  <span style="margin: auto"> - </span>
                  <div class="max-price">
                    <div>
                      <SfInput type="number" :placeholder="t('MAX_TEXT')" v-model="oFilter.selected[1]"
                        @change="onChangeFilter(oFilter.selected, null, oFilter)" />
                    </div> 
                    <div>{{ t('MAX_PRICE_RANGE') }}</div>
                  </div>
                </div>

                <div v-else :style="{ 'padding': '0 20px', 'height': '60px'}" :class="{ 'price-slider-section': oFilter.sName == 'PRICE' }">
                  <SfRange :active="oFilter.selected" :config="setRangeConfig(oFilter)"
                    @change="onChangeFilter($event, null, oFilter)" class="filters__item filters__range" />
                </div>
              </div>
              <div>
                <hr class="sf-divider" />
              </div>
            </div>
          </div>
          <div v-if="oFilter.grandCategory" :class="{ 'sub_child_filter': oFilter.isSubChild, 'child_filter': oFilter.isChild}">
            <SfAccordion open="grandAccordion" class="grand-category-accordion" ref="grandFilterAccordion">
              <template v-for="filterKey in Object.keys(oFilter.grandCategory)">
                <SfAccordionItem :header="t(filterKey)" class="filters__accordion-item" :key="filterKey" :data-filter="'grand-category-' + oFilter.sName">
                  <template #header="{header}">
                    <SfButton :aria-pressed="(grandAccordion == 'all' || grandAccordion == t(filterKey)).toString()"
                      :aria-expanded="(grandAccordion == 'all' || grandAccordion == t(filterKey)).toString()"
                      :class="{ 'is-open': (grandAccordion == 'all' || grandAccordion == t(filterKey)) }"
                      class="sf-button--pure sf-accordion-item__header grand-category-header" @click="grandAccordionClick">
                      {{ header }}
                      <SfChevron tabindex="0" class="sf-accordion-item__chevron"
                        :class="{ 'sf-chevron--right': !(grandAccordion == t(filterKey)) && grandAccordion != 'all' }" />
                    </SfButton>
                  </template>
                  <div v-for="categoryFilter in oFilter.grandCategory[filterKey]" :key="categoryFilter.sName">
                    <SfHeading :level="5" :title="truncateString(t(getFilterName(categoryFilter.sName)), 18)" class="filters__title sf-heading--left"
                      v-if="categoryFilter.aOptions.length && !categoryFilter.hideFilter"/>
                    <div v-if="categoryFilter?.showSearchBy">
                      <SfInput :placeholder="t('SEARCH')" v-model="categoryFilter.searchTerm"/>
                    </div>
                    <div class="filter-option-section max-filter-section" :ref="'element-' + categoryFilter.sName">
                      <div v-for="oOption in filterOptions(categoryFilter)" :key="oOption.value">
                        <!--  -->
                        <SfFilter :label="t(oOption.value )" :count="displayOptionLabel(oOption)" :selected="oOption?.selected"
                          class="filters__item" :class="{ 'd-none': displayOptionLabel(oOption) == 0 }"
                          @change="onChangeFilter($event, oOption, categoryFilter)">
                          <template #label="{ label }">
                            <div class="sf-filter__label dmNewParagraph">
                              <p class="my-0">{{ truncateString(label, 20) }}</p>
                            </div>
                          </template>
                          <template #count="{ count }">
                            <div class="sf-filter__count dmNewParagraph">
                              <p class="my-0">{{ count }}</p>
                            </div>
                          </template>
                        </SfFilter>
                      </div>
                    </div>
                  </div>
                </SfAccordionItem>
              </template>
            </SfAccordion>
          </div>
        </div>
      </div>
      <SfAccordion open="openAccordion" class="filters smartphone-only" ref="mobileFilterAccordion">
        <SfAccordionItem :header="t('SHOW_ON_PAGE')" class="filters__accordion-item">
          <template #header="{
            header
          }">
            <SfButton :aria-pressed="(openAccordion == t('SHOW_ON_PAGE')).toString()"
              :aria-expanded="(openAccordion == t('SHOW_ON_PAGE')).toString()"
              :class="{ 'is-open': (openAccordion == t('SHOW_ON_PAGE')) }"
              class="sf-button--pure sf-accordion-item__header" @click="accordionClick">
              {{ header }}
              <span class="filters__chosen"> {{ perPage }} {{ t('ITEMS') }} </span>
              <SfChevron tabindex="0" class="sf-accordion-item__chevron"
                :class="{ 'sf-chevron--right': !(openAccordion == t('SHOW_ON_PAGE')) }" />
            </SfButton>
          </template>
          <SfRadio v-for="value in showOnPage" :key="value" v-model="perPage" :value="value" :label="value"
            class="filters__item" @change="setFilter(perPage, null)" />
        </SfAccordionItem>
        <SfAccordionItem :header="t('SORT_BY')" class="filters__accordion-item">
          <template #header="{
            header
          }">
            <SfButton :aria-pressed="(openAccordion == t('SORT_BY')).toString()"
              :aria-expanded="(openAccordion == t('SORT_BY')).toString()"
              :class="{ 'is-open': (openAccordion == t('SORT_BY')) }" class="sf-button--pure sf-accordion-item__header"
              @click="accordionClick">
              {{ header }}
              <!-- <span class="filters__chosen"> {{ sortBy }} </span> -->
              <SfChevron tabindex="0" class="sf-accordion-item__chevron"
                :class="{ 'sf-chevron--right': !(openAccordion == t('SORT_BY')) }" />
            </SfButton>
          </template>
          <SfRadio v-for="sort in sortByOptions" :key="sort.value" v-model="sortBy" :value="sort.label"
            :label="t(sort.label)" class="filters__item" @change="setFilter(sortBy, null)" />
        </SfAccordionItem>
        <template v-for="oFilter in displayFilters">
          <SfAccordionItem :key="oFilter.sName" v-if="oFilter && oFilter.sName == 'CATEGORY'" :header="t(oFilter.sName)"
            class="filters__accordion-item" :class="{ 'd-none': (oFilter.hasDefaultFilter || oFilter.sName == 'COLOR') }">
            <template #header="{
              header
            }">
              <SfButton :aria-pressed="(openAccordion == t(oFilter.sName)).toString()"
                :aria-expanded="(openAccordion == t(oFilter.sName)).toString()"
                :class="{ 'is-open': (openAccordion == t(oFilter.sName)) }"
                class="sf-button--pure sf-accordion-item__header" @click="accordionClick">
                {{ header }}
                <span class="filters__chosen"> {{ t(category) }} </span>
                <SfChevron tabindex="0" class="sf-accordion-item__chevron"
                  :class="{ 'sf-chevron--right': !(openAccordion == t(oFilter.sName)) }" />
              </SfButton>
            </template>
            <div class="filter-option-section">
              <SfRadio v-for="oOption in oFilter.aOptions" :key="oOption.value" :label="t(oOption.value)"
                :count="displayOptionLabel(oOption)" :value="oOption.value" v-model="category" class="filters__item"
                :class="{ 'd-none': displayOptionLabel(oOption) == 0 }"
                @change="onChangeFilter($event, oOption, oFilter)" />
            </div>
          </SfAccordionItem>
          <SfAccordionItem v-else :header="t(getFilterName(oFilter.sName))" :key="'phone-' + oFilter.sName"
            class="filters__accordion-item" :class="{ 'd-none': oFilter.hasDefaultFilter }"
            :data-filter="'phone-' + oFilter.sName">
            <template #header="{
              header
            }">
              <SfButton :aria-pressed="(openAccordion == t(oFilter.sName)).toString()"
                :aria-expanded="(openAccordion == t(oFilter.sName)).toString()"
                :class="{ 'is-open': (openAccordion == t(oFilter.sName)) }"
                class="sf-button--pure sf-accordion-item__header" @click="accordionClick">
                {{ header }}
                <SfChevron tabindex="0" class="sf-accordion-item__chevron"
                  :class="{ 'sf-chevron--right': !(openAccordion == t(oFilter.sName)) }" />
              </SfButton>
            </template>
            <div v-if="oFilter?.showSearchBy">
              <SfInput :placeholder="t('SEARCH')" v-model="oFilter.searchTerm"/>
            </div>
            <div v-if="oFilter.sInputGroup == 'checkbox' && !oFilter.bIsDynamic" class="filter-option-section"
              :ref="'phone-element-' + oFilter.sName">
              <SfFilter v-for="oOption in filterOptions(oFilter)" :key="oOption.value" :label="t(oOption.value)"
                :count="displayOptionLabel(oOption)" :selected="oOption?.selected" class="filters__item"
                :class="{ 'd-none': displayOptionLabel(oOption) == 0 }"
                @change="onChangeFilter($event, oOption, oFilter)" />
            </div>
            <div v-if="showApiFields && oFilter.sInputGroup == 'checkbox' && oFilter.bIsDynamic"
              class="filter-option-section" :ref="'phone-element-' + oFilter.sName">
              <SfFilter v-for="oOption in filterOptions(oFilter)" :key="oOption._id" :label="t(oOption.label || '')"
                :count="displayOptionLabel(oOption)" :selected="oOption?.selected" class="filters__item"
                :class="{ 'd-none': displayOptionLabel(oOption) == 0 }"
                @change="onChangeFilter($event, oOption, oFilter)" />

            </div>
            <div v-if="oFilter.sInputGroup == 'radio' && !oFilter.bIsDynamic" class="filter-option-section"
              :ref="'phone-element-' + oFilter.sName">
              <SfRadio v-for="oOption in oFilter.aOptions" :key="oOption.value" :label="t(oOption.value)"
                :count="displayOptionLabel(oOption)" :value="oOption.value" :active="oFilter?.selected"
                class="filters__item" :class="{ 'd-none': displayOptionLabel(oOption) == 0 }"
                @change="onChangeFilter($event, oOption, oFilter)" />
            </div>
            <div class="dmNewParagraph" :class="{ 'display-none': !hiddenPhoneFilterList.includes(oFilter.sName) }">
              <p> {{ t('NO_FILTER_AVAILABLE_IN_CATEGORY') }}</p>
            </div>
            <!-- <div
              v-if="oFilter.sInputGroup == 'checkboxWithColor' && !oFilter.bEnableOptionGroup && oFilter.aSubGroupIds.length > 0">
              <template v-for="oSubGroup in oFilter.aSubGroupIds">
                <span v-if="oSubGroup" :key="oSubGroup?.value">
                  <SfHeading :level="5" :title="getTranslatedLabel(oSubGroup.sName)" class="sf-heading--left" />
                  <div class="filters__colors">
                    <div v-for="oColorOption in oSubGroup.aOptions" :key="oSubGroup.sName + oColorOption.value">
                      <SfColor
                        :color="oColorOption.hex" :selected="isColorSelected(oColorOption)" class="filters__color"
                        @click="onChangeFilter($event, oColorOption, oSubGroup)"/>
                        <p class="filters__color_label"> 
                          <span>{{ t(oColorOption.value) }}</span>  
                          <span v-if="oColorOption.count">{{ oColorOption.count }}</span>
                        </p>
                    </div>
                  </div>
                </span>
              </template>
            </div> -->
            <div v-if="oFilter.sInputGroup == 'slider'">
              <div class="price-slider" v-if="oFilter.sName == 'PRICE' && oFilter.selected?.length > 0">
                <div class="min-price"> 
                  <div>
                    <SfInput type="number" :placeholder="t('MIN_TEXT')" v-model="oFilter.selected[0]"
                      @change="onChangeFilter(oFilter.selected, null, oFilter)" />
                  </div>
                  <div>{{ t('MIN_PRICE_RANGE') }}</div>
                </div>
                <span style="margin: auto"> - </span>
                <div class="max-price">
                  <div>
                    <SfInput type="number" :placeholder="t('MAX_TEXT')" v-model="oFilter.selected[1]"
                      @change="onChangeFilter(oFilter.selected, null, oFilter)" />
                  </div> 
                  <div>{{ t('MAX_PRICE_RANGE') }}</div>
                </div>
              </div>

              <div v-else :style="{ 'padding': '0 20px', 'height': '60px'}" :class="{ 'price-slider-section': oFilter.sName == 'PRICE' }">
                <SfRange :active="oFilter.selected" :config="setRangeConfig(oFilter)"
                  @change="onChangeFilter($event, null, oFilter)" class="filters__item filters__range" />
              </div>
            </div>
          </SfAccordionItem>
          <div v-if="oFilter.grandCategory" :key="oFilter.sName">
            <SfAccordion open="grandAccordion" class="grand-category-accordion" ref="grandFilterAccordion">
              <template v-for="filterKey in Object.keys(oFilter.grandCategory)">
                <SfAccordionItem :header="t(filterKey)" class="filters__accordion-item" :key="filterKey" :data-filter="'grand-category-' + oFilter.sName">
                  <template #header="{header}">
                    <SfButton :aria-pressed="(grandAccordion == 'all' || grandAccordion == t(filterKey)).toString()"
                      :aria-expanded="(grandAccordion == 'all' || grandAccordion == t(filterKey)).toString()"
                      :class="{ 'is-open': grandAccordion == 'all' || grandAccordion == t(filterKey) }"
                      class="sf-button--pure sf-accordion-item__header grand-category-header" @click="grandAccordionClick">
                      {{ header }}
                      <SfChevron tabindex="0" class="sf-accordion-item__chevron"
                        :class="{ 'sf-chevron--right': grandAccordion != 'all' || (grandAccordion != 'all' && !(grandAccordion == t(filterKey)))  }" />
                    </SfButton>
                  </template>
                  <div v-for="categoryFilter in oFilter.grandCategory[filterKey]" :key="categoryFilter.sName">
                    <SfHeading :level="5" :title="truncateString(t(getFilterName(categoryFilter.sName)), 18)" class="filters__title sf-heading--left"
                      v-if="categoryFilter.aOptions.length && !categoryFilter.hideFilter"/>
                    <div v-if="categoryFilter?.showSearchBy">
                      <SfInput :placeholder="t('SEARCH')" v-model="categoryFilter.searchTerm"/>
                    </div>
                    <div class="filter-option-section max-filter-section" :ref="'element-' + categoryFilter.sName">
                      <div v-for="oOption in filterOptions(categoryFilter)" :key="oOption.value">
                        <!--  -->
                        <SfFilter :label="t(oOption.value )" :count="displayOptionLabel(oOption)" :selected="oOption?.selected"
                          class="filters__item" :class="{ 'd-none': displayOptionLabel(oOption) == 0 }"
                          @change="onChangeFilter($event, oOption, categoryFilter)">
                          <template #label="{ label }">
                            <div class="sf-filter__label dmNewParagraph">
                              <p class="my-0">{{ truncateString(label, 20) }}</p>
                            </div>
                          </template>
                          <template #count="{ count }">
                            <div class="sf-filter__count dmNewParagraph">
                              <p class="my-0">{{ count }}</p>
                            </div>
                          </template>
                        </SfFilter>
                      </div>
                    </div>
                  </div>
                </SfAccordionItem>
              </template>
            </SfAccordion>
          </div>
        </template>
      </SfAccordion>
      <!-- <div class="filters desktop-only" v-if="updatedFilters && !widgetConfig.showProductFilter">
        line 206
        :class="{ 'd-none': oFilter.hasDefaultFilter, 'display-none': hiddenFilterList.includes(oFilter.sName) }"
        <div v-for="oFilter in displayFilters" :key="oFilter.sName">
          <SfHeading :level="4" :title="t(oFilter.sName)" class="filters__title sf-heading--left"
            :class="checkFilterOptions(oFilter.sName)" />
          <div v-if="oFilter.sInputGroup == 'checkbox' && !oFilter.bIsDynamic" class="filter-option-section"
            :ref="'element-' + oFilter.sName">
            <div v-for="oOption in oFilter.aOptions" :key="oOption.value">
              <SfFilter :label="t(oOption.value)" :count="displayOptionLabel(oOption)" :selected="oOption.selected"
                class="filters__item" :class="{ 'd-none': displayOptionLabel(oOption) == 0 }"
                @change="onChangeFilter($event, oOption, oFilter)">
                <template #label="{ label }">
                  <div class="sf-filter__label dmNewParagraph">
                    <p class="my-0">{{ label }}</p>
                  </div>
                </template>
                <template #count="{ count }">
                  <div class="sf-filter__count dmNewParagraph">
                    <p class="my-0">{{ count }}</p>
                  </div>
                </template>
              </SfFilter>
            </div>
          </div>
          <div v-if="showApiFields && oFilter.sInputGroup == 'checkbox' && oFilter.bIsDynamic"
            class="filter-option-section max-filter-section" :ref="'element-' + oFilter.sName">
            <SfFilter v-for="oOption in oFilter.aOptions" :key="oOption._id" :label="t(oOption.label || '')"
              :count="displayOptionLabel(oOption)" :selected="oOption.selected" class="filters__item"
              :class="{ 'd-none': displayOptionLabel(oOption) == 0 }" @change="onChangeFilter($event, oOption, oFilter)">
              <template #label="{ label }">
                <div class="sf-filter__label dmNewParagraph">
                  <p class="my-0">{{ label }}</p>
                </div>
              </template>
              <template #count="{ count }">
                <div class="sf-filter__count dmNewParagraph">
                  <p class="my-0">{{ count }}</p>
                </div>
              </template>
            </SfFilter>
          </div>
          <div v-if="oFilter.sInputGroup == 'radio' && !oFilter.bIsDynamic" class="filter-option-section"
            :ref="'element-' + oFilter.sName">
            <SfRadio v-for="oOption in oFilter.aOptions" :key="oOption.value" :label="t(oOption.value)"
              :count="displayOptionLabel(oOption)" :value="oOption.value" v-model="oFilter.selected" class="filters__item"
              :class="{ 'd-none': displayOptionLabel(oOption) == 0 }" @change="onChangeFilter($event, oOption, oFilter)">
              <template #label="{ label }">
                <div class="sf-filter__label dmNewParagraph">
                  <p class="my-0">{{ label }}</p>
                </div>
              </template>
              <template #count="{ count }">
                <div class="sf-filter__count dmNewParagraph">
                  <p class="my-0">{{ count }}</p>
                </div>
              </template>
            </SfRadio>
          </div>
          <div
            v-if="oFilter.sInputGroup == 'checkboxWithColor' && !oFilter.bEnableOptionGroup && oFilter.aSubGroupIds.length > 0">
            <span v-for="oSubGroup in oFilter.aSubGroupIds" :key="oSubGroup.sName">
              <SfHeading :level="5" :title="getTranslatedLabel(oSubGroup.sName)" class="sf-heading--left" />
              <div class="filters__colors">
                <SfColor v-for="oColorOption in oSubGroup.aOptions" :key="oSubGroup.sName + oColorOption.value"
                  :color="oColorOption.hex" :selected="isColorSelected(oColorOption)" class="filters__color"
                  @click="onChangeFilter($event, oColorOption, oSubGroup)" />
              </div>
            </span>
          </div>
          <div v-if="oFilter.sInputGroup == 'slider'" :style="{ 'padding': '0 10px', 'height': '60px' }">
            <SfRange v-model="oFilter.selected" :config="setRangeConfig(oFilter)"
              @change="onChangeFilter($event, null, oFilter)" class="filters__item filters__range" />
          </div>
          <div class="filters__buttons desktop-only" v-if="updatedFilters"> widgetConfig.showProductFilter
            <SfButton class="sf-button--full-width dmWidget" name="applySelectedFilters" @click="setSelectedFilters">
              <span class="text">{{ t('APPLY_FILTER') }}</span>
            </SfButton>
            <SfButton class="sf-button--full-width filters__button-clear" @click="clearAllFilters">{{ t('CLEAR_ALL') }}
            </SfButton>
          </div>
        </div>
      </div> -->
      <!-- <template #content-bottom>
      </template> -->
      <div class="filters__buttons">
        <SfButton class="sf-button--full-width dmWidget" name="applySelectedFilters" @click="setSelectedFilters">
          <span class="text">{{ t('APPLY_FILTER') }}</span>
        </SfButton>
        <SfButton class="sf-button--full-width filters__button-clear" @click="clearAllFilters">{{ t('CLEAR_ALL') }}
        </SfButton>
      </div>
    </component>
  </div>
</template>


<script>
import { ref } from 'vue';
import { store } from '../services/store.js';
import {
  SfHeading,
  SfSidebar,
  SfFilter,
  SfColor,
  SfRadio,
  SfRange,
  SfAccordion,
  SfButton,
  SfChevron,
  SfInput
} from '@storefront-ui/vue';

export default {
  name: 'filtersSidebar',
  props: ['token', 'openSidebar', 'sidebarAccordion', 'businessDetails', 'dudaConfig', 'widgetConfig'],
  data() {
    return {
      filtersList: store.filtersList,
      rangeValue: [
        0, 10000
      ],
      isOpenColorBox: true,
      openAccordion: '',
      grandAccordion: 'all',
      dynamicPropertyList: store.dynamicPropertyList,
      selectedFilters: store.selectedFilters,
      sortBy: store.sortBy,
      sortByOptions: store.sortByOptions,
      category: "",
      perPage: "",
      showOnPage: ['20', '40', '60'],
      updatedFilters: false,
      headers: {},
      categoryFiltersList: store.categoryFiltersList,
      colorPropertiesList: store.colorPropertiesList,
      genderCountList: [],
      hiddenFilterList: [],
      hiddenPhoneFilterList: [],
      constSingleKeyFilter: [
        { key: "b", value: true },
        { key: "n", value: { $gt: 0 } },
        { key: "a", value: { $expr: { $gt: [{ $size: '$#|field|#' }, 0] } } }
      ],
      showApiFields: false,
      language: this.dudaConfig?.locale || 'en',
      coreApiUrl: store.coreApiUrl,
      iBusinessId: '',
      currencyFormats: store.currencyFormats,
      selectedCurrency: store.selectedCurrency,
      minVal: 0,
      maxVal: 100,
    }
  },
  components: {
    SfHeading,
    SfSidebar,
    SfFilter,
    SfColor,
    SfRadio,
    SfRange,
    SfAccordion,
    SfButton,
    SfChevron,
    SfInput
  },
  computed: {
    displayFilters() {
      let aFilterList = store.filtersList?.length ? store.filtersList?.filter((filter) => typeof (filter._id) != 'undefined') : [];
      let aTempFilterList = [];
      let isSetNewGrandCategory = false
      for (let i = 0; i < aFilterList.length; i++) {
        if (aFilterList[i].categoryLevel == 'subCategory') { // aFilterList[i].parentValue && !aFilterList[i].mainParentValue
          aTempFilterList.push({
            ...aFilterList[i],
            isChild: true,
            isSubChild: false,
          })
        } else if(aFilterList[i].categoryLevel == 'grandCategory'){
          aTempFilterList.push({
            ...aFilterList[i],
            isChild: false,
            isSubChild: true,
          })
        }
        
        // else if (aFilterList[i].categoryLevel == 'grandCategory') { // aFilterList[i].parentValue && aFilterList[i].mainParentValue
        //   const parentFilterIndex = aTempFilterList.findIndex( subCategory => subCategory.propertyId == aFilterList[i].parentPropertyId || subCategory.iPropertyId == aFilterList[i].parentPropertyId);
        //   if(parentFilterIndex > -1){
        //     const parentValue = aFilterList[i].parentValue.value;
        //     if(aTempFilterList[parentFilterIndex].grandCategory && aTempFilterList[parentFilterIndex].grandCategory[parentValue]){
        //       aTempFilterList[parentFilterIndex].grandCategory[parentValue].push(aFilterList[i]);
        //     } else {
        //       if(!aTempFilterList[parentFilterIndex].grandCategory){
        //         aTempFilterList[parentFilterIndex].grandCategory = { };
        //         isSetNewGrandCategory = true;
        //       }
        //       aTempFilterList[parentFilterIndex].grandCategory[parentValue] =  [aFilterList[i]];
        //     }
        //   }
        // } 
        else {
          aTempFilterList.push({
            ...aFilterList[i],
            isChild: false,
            isSubChild: false,
          })
        }
      }

      if(isSetNewGrandCategory){
        this.grandAccordionClick('all');
      }
      aTempFilterList.forEach(data => {
        // displayOptionLabel(oOption) == 0
        if (data.sName == 'PRICE' && data.sInputGroup === 'slider') {
          const selectedStart = data.selected?.length ? data.selected[0] : 0;
          const selectedEnd = data.selected?.length ? data.selected[1] : 1000;
          if (data.selected && data.selected.length) {
            this.minVal = Number(selectedStart);
            this.maxVal = Number(selectedEnd);
          }
        }
        if (data.bIsDynamic && data.iPropertyId) {
          const findObj = this.getDataByAttr(aTempFilterList, 'iPropertyId', data.iPropertyId)
          const index = aTempFilterList.indexOf(findObj);
          const aChildrens = aTempFilterList.filter(f => f.iPropertyId === data.iPropertyId)
          this.insertAt(aTempFilterList, index, ...aChildrens);
        }
      });

      let uniqueList = ref([]);
      let sType = false;
      if(aTempFilterList.length){
        uniqueList.value = aTempFilterList.filter(this.isUnique);
        const categoryFilters = uniqueList.value?.filter(f=>f.sName === 'CATEGORY');
        const CATEGORY = categoryFilters?.length > 0 ? categoryFilters[0] : undefined;
        const aOptions = CATEGORY?.aOptions || [];
        sType = aOptions.length ? aOptions?.filter(opt => opt.selected)[0] : false;
      }

      uniqueList.value = uniqueList.value?.map(filter=>{
        
        let hideFilter = false;
        if(filter.aOptions) hideFilter = this.displayFilterLabel(filter);
        if(['PRICE'].includes(filter.sName)) hideFilter = false;

        if(filter.sInputGroup == 'checkboxWithColor' && !filter.bEnableOptionGroup && filter.aSubGroupIds?.length){
          let aSubGroupIds = filter.aSubGroupIds.map(sgo => {
            if(sType && ['JEWEL','WATCH'].includes(sType.value)){
              if(Object.keys(sgo).includes('WATCH_STRAP_COLOR') || Object.keys(sgo).includes('WATCH_CASE_COLOR')){
                return {
                  ...sgo,
                  isHideTag: ['JEWEL'].includes(sType.value)
                }
              } else if(Object.keys(sgo).includes('JEWEL_COLOR')){
                return {
                  ...sgo,
                  isHideTag: ['WATCH'].includes(sType.value)
                }
              } else if(sgo?.sName == "GENERAL_COLORS"){
                return {
                  ...sgo,
                  isHideTag: ['WATCH'].includes(sType.value)
                }
              } else{
                return {
                  ...sgo,
                  sName: ""
                }
              }
            }
          });

          return {
            ...filter,
            aSubGroupIds
          }
        }else{

          if(!['COLOR', "PRICE"].includes(filter.sName)){
            const options = filter.aOptions ? filter.aOptions : [];
            options.forEach(opt=>{
              opt = JSON.parse(JSON.stringify(opt))
              if(this.widgetConfig.gender.includes(opt.sKey) && opt?.selected){
                hideFilter = true;
              }
              if(this.widgetConfig.category){
                const categories = this.widgetConfig.category.map(d=>d.property.sPropertyOptionName);
                if(categories.includes(opt.sKey) && opt?.selected){
                  hideFilter = true;
                }
              }
              if(this.widgetConfig.subCategory){
                const categories = this.widgetConfig.subCategory.map(d=>d.property.sPropertyOptionName);
                if(categories.includes(opt.sKey) && opt?.selected){
                  hideFilter = true;
                }
              }
            });

            if(!hideFilter && filter.categoryLevel == 'subCategory'){
              const grandCategoryFilter = uniqueList.value.find( filter => filter.grandCategory);
              if(grandCategoryFilter?.grandCategory){
                const subCategoryName = Object.keys(grandCategoryFilter.grandCategory)[0];
                const filterNames = grandCategoryFilter.grandCategory[subCategoryName]?.map( (filter) => this.getFilterName(filter.sName));
                hideFilter = filterNames.includes(this.getFilterName(filter.sName));
              }
            }
          }
          return {
            ...filter,
            hideFilter,
            searchTerm: ''
          }
        }
      })

      uniqueList.value.sort((a, b) => {
        if(a.categoryLevel == 'subCategory' && b.categoryLevel == 'subCategory'){
          const aHasNeedToAppend = a.aOptions.some(e => e.bNeedsToAppend);
          const bHasNeedToAppend = b.aOptions.some(e => e.bNeedsToAppend);
          if(aHasNeedToAppend && bHasNeedToAppend){
            return 0;
          }
          if (aHasNeedToAppend) {
            return -1;
          }
          if (bHasNeedToAppend) {
            return 1;
          }
        }
      });

      //Remove indentation if parent is hidden and update grand category position
      uniqueList.value?.forEach(( element, filterIndex) => {
        if(element.parentValue && this.widgetConfig.category){
          if(element.isChild && this.widgetConfig.category){
            const categories = this.widgetConfig.category.map(d=>d.sKey);
            if(categories.includes(element.parentValue.sKey)) element.isChild = false;
          }else if(element.isSubChild && this.widgetConfig.subCategory){
            const categories = this.widgetConfig.subCategory.map(d=>d.sKey);
            if(categories.includes(element.parentValue.sKey)) element.isSubChild = false;
          } 
          if(element.parentValue.hasDefaultFilter){
            element.isChild = false;
            element.isSubChild = false;
          }
        } 
        if(element.categoryLevel == "grandCategory") {
          const parentFilterIndex = uniqueList.value.findIndex( subCategory => subCategory.propertyId == element.parentPropertyId || subCategory.iPropertyId == element.parentPropertyId);
          this.arrayMovePosition(uniqueList.value, filterIndex, parentFilterIndex+ 1);
        }
      });

      uniqueList.value = uniqueList.value?.map(filter => {
        let aOptions = filter?.aOptions || [];
        if(['BRAND'].includes(filter.sName)){
        // sort by name
        aOptions.sort((a, b) => {
            const nameA = a.label.toUpperCase(); // ignore upper and lowercase
            const nameB = b.label.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          });
        }
        return {
          ...filter,
          aOptions
        }
      });

      // uniqueList = uniqueList.map(filter => {
      //   let hideFilter = false;
      //   if(filter.aOptions) hideFilter = this.displayFilterLabel(filter);
      //   if(['PRICE'].includes(filter.sName)) hideFilter = false;
      //   return {
      //     ...filter,
      //     hideFilter
      //   }
      // });
      
      // console.log("uniqueList", uniqueList.value)
      return uniqueList.value;
    },
    componentTag() {
      return this.widgetConfig.showProductFilter == 'collapsed' || this.dudaConfig?.device == "mobile" ? "SfSidebar" : "div";
    }
  },
  created() {
    store.$on('sortByChanged', () => {
      this.sortBy = store.sortBy;
    });
    // store.$on('widgetConfigSet', () => {
    //   // console.log('widgetConfigSet event received on filter sidebar')
    //   this.widgetConfig = store.widgetConfig;
    // });
    store.$on('change', () => {
      this.requestParams = store.requestParams;
      // console.log('filters sidebar received change', JSON.parse(JSON.stringify(this.requestParams)))
      this.colorPropertiesList = store.colorPropertiesList;
      this.filtersList = store.filtersList;
      this.updatedFilters = true;
    })
  },
  async mounted() {
    //console.log('filter sidebar mounted widgetConfig = ', store.jps(this.widgetConfig))
    this.perPage = store.requestParams.limit.toString();


    if (this.widgetConfig.showProductFilter == 'always') {
      setTimeout(() => { this.showApiFields = true; }, 1000);
    }
  },
  methods: {

    arrayMovePosition(arr, fromIndex, toIndex) {
      console.log("here")
      let element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    },

    truncateString(str, num) {
      if (str.length > num) {
        return str.slice(0, num) + "...";
      } else {
        return str;
      }
    },

    insertAt(array, index, ...elementsArray) {
      array.splice(index, 0, ...elementsArray);
    },

    isUnique(object, index, self) {
      return (
        index === self.findIndex((obj) => obj.sName === object.sName)
      );
    },

    getDataByAttr(arr, attr, value) {
      let index = arr.findIndex((x) => x[attr] == value);
      return arr[index];
    },

    // fetchFilterDetails(){
    //   this.fetchDynamicProperties();
    //   store.filtersList = store.filtersList.map( (filter)=> {
    //     if(filter.bIsDynamic){
    //       filter['aOptions'] = this.fetchOptions(filter);
    //     } else if(filter.sInputGroup == "slider"){
    //       filter['aOptions'] = [{value: filter.title+'opt'}];
    //     }
    //     return filter;
    //   });
    // },

    // onChangePriceFilter(event, oFilter, type) {
    //   // console.log("onChangePriceFilter", event.target.value, oFilter, type);
    // },

    onChangeFilter(event, oOption, oFilter) {
      // console.log("On click filter", oOption, oFilter, event)
      // if (oFilter.sName == 'PRICE' && oFilter.sInputGroup === 'slider') {
      //   this.minVal = parseFloat(oFilter.selected[0]);
      //   this.maxVal = parseFloat(oFilter.selected[1]);
      //   console.log("PRICE=>", this.minVal, this.maxVal);
      // }
      store.onChangeFilter(event, oOption, oFilter, true);
    },

    displayOptionName(option, name) {
      let optName = "";
      if (name && name.includes('{lang}') && option[name.replace('{lang}', '')]) {
        optName = option[name.replace('{lang}', '')][this.language] ? option[name.replace('{lang}', '')][this.language] : option[name.replace('{lang}', '')]['en-us']
      } else {
        optName = option[name] || 'Unknown';
      }
      return optName;
    },

    displayOptionLabel(option) {
      if (option.count) {
        return option.count;
      } else {
        let optData = this.dynamicPropertyList?.filter((property) => (property.sPropertyOptionName == option.sKey && property.iPropertyId == option.iPropertyId) || (property._id == option.sKey));
        return (optData.length > 0 ? optData[0].count : 0);
      }
    },

    displayFilterLabel(filter) {
      let aOptions = JSON.parse(JSON.stringify(filter.aOptions));
      
      let countsList = [];
      aOptions.forEach(option => {
        if (option.count) {
          countsList.push(option.count);
        } else {
          let optData = this.dynamicPropertyList?.filter((property) => (property.sPropertyOptionName == option.sKey && property.iPropertyId == option.iPropertyId) || (property._id == option.sKey));
          countsList.push((optData?.length > 0 ? optData[0].count : 0));
        }
      });

      const allZeros = countsList.every(element => element === 0);
      const visibleOptions = countsList.filter(element => element != 0);
      if(visibleOptions?.length >= 10){
        filter.showSearchBy = true;
      }
      if (filter.sName === 'COLOR') {
        filter.aSubGroupIds.forEach(subGrop => {
          if(!subGrop.aOptions.includes(undefined)){
            subGrop.aOptions.forEach(color => { color['count'] = this.findColorCount(color, subGrop); })
          }
        });
      }
      return allZeros;
    },

    findColorCount(color, filter){
      const count = this.dynamicPropertyList?.filter(f=> f && (f.sPropertyName === filter.sName && f.sPropertyOptionName === color.value));
      return count.length ? count[0].count : 0;
    },

    setRangeConfig(filter) {
      // console.log("setRangeConfig called with filter", filter)
      const currencyDetails = store.currencyFormats.find(currency => currency.symbol == store.selectedCurrency) || store.currencyFormats;
      let [min, max] = [0, 1000]
      if(this.widgetConfig.oRange) [min, max] = [Number(this.widgetConfig.oRange.nMin), Number(this.widgetConfig.oRange.nMax)]

      const selectedStart = filter?.selected?.length ? filter?.selected[0] : min;
      const selectedEnd = filter?.selected?.length ? filter?.selected[1] : max;
      return {
        'start': [Number(selectedStart), Number(selectedEnd)],
        'range': {
          'min': min,
          'max': max
        },
        'step': 1,
        "connect": true, "direction": "ltr",
        "orientation": "horizontal", "behaviour": "tap-drag",
        "tooltips": true, "keyboardSupport": true,
        "format": {
          to: function (value) { return filter.oRangeLabel.sLabel + ' ' + (Math.floor(value).toString()).toLocaleString((currencyDetails?.stringFormat || '€'), { currency: (currencyDetails?.currency || 'EUR') }) },
          from: function (value) { return (Math.floor(value).toString()).toLocaleString((currencyDetails?.stringFormat || '€'), { currency: (currencyDetails?.currency || 'EUR') }) }
        }
      };
    },

    onClick(filter, reference, event) {
      event.preventDefault();
      event.stopPropagation();
      Object.entries(reference).map(menu => {
        if (menu[1][0].show) {
          menu[1][0].close();
        }
      });

      let elementCordinates = event.currentTarget.getBoundingClientRect();
      let eventCordinates = {
        clientX: Math.floor(elementCordinates.x),
        clientY: Math.floor(elementCordinates.y) + elementCordinates.height
      }
      reference[filter.title][0].open(eventCordinates, filter);
    },

    getFilterDetails(filter) {
      let name = filter.oName && filter.oName[this.language] ? filter.oName[this.language] : filter.sName;
      name = filter.hex ? (filter.filter + ' : ' + filter.value) : name;
      return name ? name : filter.value;
    },
    setSelectedFilters() {
      // console.log('im here setSelectedFilters ---------------------')
      let categoryFilter = store.filtersList.filter((filter) => filter.sName == 'CATEGORY')[0];
      // console.log('im here setSelectedFilters categoryFilter ---------------------', categoryFilter && !categoryFilter.hasDefaultFilter)
      if (categoryFilter && !categoryFilter.hasDefaultFilter) {
        let brandIndex = store.filtersList.findIndex((filter) => filter.sName == 'BRAND' && filter.aOptions.findIndex((opt) => opt?.selected) == -1);
        if (brandIndex > -1) {
          store.fetchOptions(store.filtersList[brandIndex]);
        }
      } else if (categoryFilter && categoryFilter.hasDefaultFilter) {
        let categoryFilters = store.requestParams.oFilterBy.aCategory.filter((filterOpts) => 
          filterOpts.iPropertyId == categoryFilter.iPropertyId
        );
        if (categoryFilters.length > 1) {
          store.requestParams.oFilterBy.aCategory =
            store.requestParams.oFilterBy.aCategory.filter((filterOpts) => 
              filterOpts.iPropertyId == categoryFilter.iPropertyId
            );
        }else if(categoryFilters.length == 0){
          store.resetDefaultFilters();
        }
      }
      // console.log('filter sidebar setSelectedFilters emit change', store.requestParams.oFilterBy)
      this.$emit('change');
      this.$emit('closeSideBar');
    },
    clearAllFilters() {
      // console.log('im here clearing filters -------------------')
      store.requestParams.oFilterBy = {
        bFilterOutOnWebShop: false
      };
      // store.fetchDynamicProperties();
      // store.fetchGenderCount();
      store.resetDefaultFilters();
      // this.category = "";
      this.selectedFilters = [];
      if (this.widgetConfig.showProductFilter == 'collapsed') {
        this.showApiFields = false;
      }
      // console.log('calling getFiltersList from clearAllFilters')
      this.$cookies.set('filtersData', store.requestParams.oFilterBy);
      store.getFiltersList();
    },
    setFilter(filter, event) {
      if (event) {
        filter.selected = Boolean(event);
      }
      this.updatedFilters = false;
      setTimeout(() => { this.updatedFilters = true; }, 10);
    },
    isColorSelected(color) {
      return color.selected ? color.selected : false;
    },
    getTranslatedLabel(name) {
      let label = '';
      let keyList = name?.split(/[{}]/) || [];
      if (keyList.length > 0) {
        keyList.forEach((key) => {
          label += this.$translate.text(key.trim('')) + ' ';
        });
        return label;
      } else {
        return name;
      }

    },
    accordionClick(event) {
      let targetElement = event?.currentTarget?.offsetParent;
      if (targetElement && targetElement?.childNodes[1]?.className?.includes('display-none')) {
        targetElement.childNodes[1].classList.remove('display-none');
        this.$refs.mobileFilterAccordion?.$el?.childNodes?.forEach((element) => {
          if (targetElement.childNodes[0] != element.firstChild && !element.lastChild.className.includes('display-none')) {
            element.lastChild.classList.add('display-none')
          }
        });
        let sIndex = event?.target?.innerText.indexOf("\n")
        this.openAccordion = sIndex > -1 ? event?.target?.innerText?.substr(0, sIndex) : event?.target?.innerText;
      } else if (targetElement && !targetElement.childNodes[1]?.className?.includes('display-none') && targetElement.childNodes[1]?.className?.length >= 0) {
        targetElement.childNodes[1]?.classList.add('display-none');
        this.openAccordion = '';
      }
      let filterName = targetElement?.getAttribute('data-filter')?.replace('phone-', '');
      this.checkFilterOptions(filterName, this.dudaConfig?.device == "mobile");
    },
    grandAccordionClick(event){
      const filterIndex = this.dudaConfig?.device == "mobile" ? 1 : 0;
      if(event == 'all'){
        setTimeout(() => {
          
          this.$refs.grandFilterAccordion[filterIndex]?.$el?.childNodes?.forEach((element) => {
            if (element.lastChild.className.includes('display-none')) {
              element.lastChild.classList.remove('display-none')
            }
          });
          this.grandAccordion = 'all';
        }, 1000);
      } else {
        let targetElement = event?.currentTarget?.offsetParent;
        if (targetElement && targetElement?.childNodes[1]?.className?.includes('display-none')) {
          targetElement.childNodes[1].classList.remove('display-none');
          this.$refs.grandFilterAccordion[filterIndex]?.$el?.childNodes?.forEach((element) => {
            if (targetElement.childNodes[0] != element.firstChild && !element.lastChild.className.includes('display-none')) {
              element.lastChild.classList.add('display-none')
            }
          });
          let sIndex = event?.target?.innerText.indexOf("\n")
          this.grandAccordion = sIndex > -1 ? event?.target?.innerText?.substr(0, sIndex) : event?.target?.innerText;
        } else if (targetElement && !targetElement.childNodes[1]?.className?.includes('display-none') && targetElement.childNodes[1]?.className?.length >= 0) {
          targetElement.childNodes[1]?.classList.add('display-none');
          this.grandAccordion = '';
        }
        let filterName = targetElement?.getAttribute('data-filter')?.replace('grand-category-', '');
        this.checkFilterOptions(filterName);
      }
    },
    checkFilterOptions(event, forPhone = false) {
      // console.log('checkFilterOptions', {event, forPhone})
      let clientHeight = {};
      let self = this;
      setTimeout(() => {
        let elementName = forPhone ? `phone-element-${event}` : `element-${event}`;
        // console.log({ elementName })
        let list = forPhone ? this.hiddenPhoneFilterList : this.hiddenFilterList;
        // console.log({list});
        clientHeight = self.$refs[elementName] ? self.$refs[elementName][0] : 0;
        // console.log({ clientHeight })
        if (clientHeight?.clientHeight < 24 && !list.includes(event)) {
          // console.log('if')
          list.push(event);
        } else if (clientHeight?.clientHeight > 24 && list.includes(event)) {
          let fIndex = list.indexOf(event)
          // console.log('else if fIndex = ', fIndex )
          list.splice(fIndex);
        }
        forPhone ? this.hiddenPhoneFilterList = list : this.hiddenFilterList = list;
        // console.log(735, this.hiddenFilterList)
      }, 200);
    },
    filterOptions(oFilter) {
      if(oFilter.searchTerm?.trim() != "" && oFilter.searchTerm?.length > 2){
        return oFilter.aOptions.filter(
          (option) => {
            if(oFilter.bIsDynamic){
              return this.t(option.label).toLowerCase().includes(oFilter.searchTerm.toLowerCase());
            } else {
              return this.t(option.value).toLowerCase().includes(oFilter.searchTerm.toLowerCase());
            }
          }
        );
      }else {
        return oFilter.aOptions;
      }
    },
    getFilterName(filterName){
      const filteNameSplit = filterName.split('_FN_');
      return filteNameSplit[filteNameSplit.length -1 ];
    }
  },
  watch: {
    openSidebar: {
      handler(value) {
        // console.log(value)
        if (value) {
          setTimeout(() => { this.showApiFields = true; }, 800);
        } else {
          this.showApiFields = false;
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@storefront-ui/vue/styles";

.sidebar {
  flex: 0 0 15%;
  padding: var(--spacer-sm);
  border: 1px solid var(--c-light);
  border-width: 0 1px 0 0;

  .filters {
    &__accordion-item {
      left: auto !important;
      right: auto !important;
      margin-left: auto !important;
      margin-right: auto !important;
      width: auto !important;
    }

    ::v-deep .sf-radio .sf-radio__container {
      padding: 0 !important;
    }
  }
}

.max-filter-section {
  max-height: 215px;
  overflow-y: auto;
  padding: 0 15px;
}

.sidebar-filters {
  --sidebar-title-display: none;
  --sidebar-top-padding: 0;

  @include for-desktop {
    --sidebar-content-padding: 0 var(--spacer-xl);
    --sidebar-bottom-padding: 0 var(--spacer-xl);
  }
}

.grand-category-accordion .sf-accordion-item{
  border-bottom: 1px solid var(--c-light) !important;
  padding-left: 5px;
}
.grand-category-header{
  font-size: var(--h5-font-size) !important;
}
.filters {
  &__title {
    --heading-title-font-size: var(--font-size--xl);
    margin: var(--spacer-xl) 0 var(--spacer-base) 0;

    &:first-child {
      margin: var(--spacer-sm) 0 var(--spacer-xs) 0;
    }
  }

  &__color_label{
    margin: auto 10px;
  }

  &__color {
    margin: var(--spacer-xs) var(--spacer-xs) var(--spacer-xs) 0;
    height: 20px !important;
    min-width: 20px !important;
  }

  &__chosen {
    color: var(--c-text-muted);
    font-weight: var(--font-weight--normal);
    font-family: var(--font-family--secondary);
    position: absolute;
    right: var(--spacer-xl);
  }

  &__item {
    --radio-container-padding: 0 var(--spacer-sm) 0 var(--spacer-xl);
    --radio-background: transparent;
    --filter-label-color: var(--c-secondary-variant);
    --filter-count-color: var(--c-secondary-variant);
    --checkbox-padding: 0 var(--spacer-sm) 0 var(--spacer-xl);
    padding: var(--spacer-sm) 0;
    border-bottom: 1px solid var(--c-light);

    &:last-child {
      border-bottom: 0;
    }

    @include for-desktop {
      --checkbox-padding: 0;
      margin: var(--spacer-sm) 0;
      border: 0;
      padding: 0;
    }
  }

  &__accordion-item {
    --accordion-item-content-padding: 0;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    width: 100vw;

    &__header {
      width: 100% !important;
      justify-content: initial !important;
    }
  }

  &__buttons {
    margin: var(--spacer-sm) 0;
  }

  &__button-clear {
    --button-background: var(--c-light);
    --button-color: var(--c-dark-variant);
    margin: var(--spacer-xs) 0 0 0;
  }

  .sf-color {
    background: var(--color-background) !important;
  }

  .sf-range {
    @include for-mobile {
      width: 80vw;
      background: transparent;

      ::v-deep .noUi-base,
      ::v-deep .noUi-connects {
        height: 8px !important;
      }
    }

    ::v-deep .noUi-tooltip {
      font: initial !important;
    }
  }

  .sf-divider {
    margin-top: 10px;
    --divider-border: 1px solid var(--c-light);
  }
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.d-none {
  display: none;
}
.d-flex {
  display: flex;
}

.filter-option-section {
  padding: 0 20px;
}

.child_filter {
  margin-left: 25px !important;
}

.sub_child_filter {
  margin-left: 50px !important;
}

.filters__title h4 {
  text-transform: capitalize;
}

.price-slider-section{
  height: 20px !important;
  ::v-deep .noUi-tooltip {
    display: none !important;
  }
}
.price-slider{
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  color: var(--color_2, #2c3e50);
  --input-color: var(--color_2, #2c3e50);
  .min-price{
    justify-content: start;
    width: 45%;
  }
  .max-price{
    justify-content: end;
    width: 45%;
  }
}
.slider_inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    width: 100%;
    padding: 8px;
    border-radius: 8px;

  }
}

.filters__color_label{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.filters__colors{
  widows: 100%;
}

</style>
