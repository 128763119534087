<template>
  <div class="app product-list">
    <ProductList 
      v-if="publicToken && !loading"
      :token="publicToken"
      :dudaConfig="widgetConfig"
      :widgetConfig="widgetData"
    />
  </div>
</template>

<script>
import ProductList from './components/product-list.vue'
import {store} from './services/store.js';


export default {
  name: 'App',
  props: ['element', 'headline', 'widgetConfig'],
  data() {
    return{
      publicToken: undefined,
      requestParams: store.requestParams,
      widgetData: {},
      coreApiUrl : store.coreApiUrl,
      //websiteApiUrl: 'https://websitebuilder.2-retailgear.org',
      websiteApiUrl: 'https://websitebuilder.2-retailgear.org',
      loading: false,
      language: this.widgetConfig?.locale || 'en',
    }
  },
  components: {
    ProductList
  },
  created() {
    store.$on('widgetConfigSet', () => {
      // this.widgetConfig = store.widgetConfig;
      this.fetchLocalTranslations(this.language);
    });
  },
  mounted() {
    console.log('app mounted', this.widgetConfig);
    this.getPublicToken();
    // this.requestParams.iBusinessId = this.widgetConfig?.iBusinessId || '6182a52f1949ab0a59ff4e7b';
    this.requestParams.iBusinessId = this.widgetConfig?.iBusinessId || '6439267fb8232393f5540908';
    // store.requestParams.iBusinessId = this.widgetConfig?.iBusinessId || '645cabf73ccacbc21f3536a1';
    store.requestParams.aBusinessId = [this.requestParams.iBusinessId];
  },
  methods: {
    getPublicToken() {
      let tempToken = this.getWithExpiry('authKey');
      if(tempToken){
        this.publicToken = tempToken;
        store.setToken(this.publicToken);
        this.fetchWidgetSettings();
        return;
      }
      this.axios.get("https://auth.1-retailgear.org/api/v1/public-token").then((response) => {
        this.publicToken = response.data.data; // for all requests
        store.setToken(this.publicToken);
        this.fetchWidgetSettings();
        this.setWithExpiry('authKey', this.publicToken);
      })
    },

    setWithExpiry(key, value) {
      const now = new Date()
      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: value,
        expiry: now.setDate(now.getDate() + 3),
      }
      localStorage.setItem(key, JSON.stringify(item))
    },

		getWithExpiry(key) {
      const itemStr = localStorage.getItem(key)
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null
      }
      const item = JSON.parse(itemStr)
      // compare the expiry time of the item with the current time
      if (new Date() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
      }
      return item.value
    },

    fetchWidgetSettings(){
      this.loading = true;
      const oBody = {
        oFilterBy: {
          sAccountId: this.widgetConfig?.accountId || 'c0a40c80cc18499788e799b0c5bec862',
          sElementId: this.widgetConfig?.elementId || '1255330188',
          sPage: this.widgetConfig?.page || 'sieraden/colliers',
          sSiteId: this.widgetConfig?.siteId || 'c67f30a2',
          sWidgetId: this.widgetConfig?.widgetId || 'a2eeac4056a248fc86d4d1b2ad48677b',
          sBaseName: this.widgetConfig?.baseName || 'aca6a9ae'
        }
        // sAccountId: this.widgetConfig?.accountId || 'c0a40c80cc18499788e799b0c5bec862',
        // sElementId: this.widgetConfig?.elementId || '1954240247',
        // sPage: this.widgetConfig?.page || 'gourmette',
        // sSiteId: this.widgetConfig?.siteId || '9dcd570c',
        // sWidgetId: this.widgetConfig?.widgetId || 'a2eeac4056a248fc86d4d1b2ad48677b',
        // sBaseName: this.widgetConfig?.baseName || 'aca6a9ae'
      };
      // console.log('calling fetch widget settings list with body', oBody)
      const url = `${this.websiteApiUrl}/api/v1/widget-setting/list`;
      this.axios.post(url, oBody, store.headers).then((response) => {
        if(response?.data?.data?.result?.length){
          if(response.data.data.result[0]?.oConfig){
            this.widgetData = response.data.data.result[0].oConfig;
            // this.widgetData.category = [];
            // this.widgetData.subCategory = [];

            // this.widgetData.showProductFilter = false;
            console.log('App -> fetchWidgetSettings -> calling store set widget config with', this.widgetData)
            store.setWidgetConfig(this.widgetData);
            if(!this.widgetData?.showPagination && this.widgetData?.noProductOnPage){
              store.requestParams.limit = this.widgetData.noProductOnPage.toString();
            }
          }
        }
        this.loading = false;
      });
    },

    fetchLocalTranslations(lang){
      const headers = { headers: { Authorization: this.publicToken } };
      this.axios.get(`${this.coreApiUrl}/api/v1/translation/all/${lang}?widget='product-list'`, headers).then((result) => {
        if(result?.data?.data?.length){
          let localTranslation = {};
          localTranslation[lang] = Object.assign({}, ...result.data.data);
          this.$translate.setLocales(localTranslation);
          this.$translate.setLang(lang);
        } 
      })
    }
  }
}
</script>

<style lang="scss">
@import "~@storefront-ui/vue/styles";

.product-list{
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0.7em;
}
 
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 5px;
}
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
  --product-card-add-button-right: 0.2rem;
  --product-card-add-button-bottom: -1rem;
  --product-card-badge-top: -10px;
  --product-card-badge-left: -10px;
  --badge-padding: 2px 5px;
  --badge-border-radius: 5px;
  --price-old-margin: auto 5px;
    .products__product-card{
      --product-card-max-width: 250px !important;
    }
    @include for-mobile {
      --sidebar-z-index: 999;
      .products__product-card{
        --product-card-max-width: 42vw !important;
      }
      .sf-product-card__image-wrapper{
        max-height: 70vw !important;
      }
      .sf-image, .sf-image--placeholder{
        max-width: 100%;
        max-height: 200px;
      }
      .sf-accordion-item__header{
        width: 95vw !important;
        justify-content: space-between;
        margin: 10px !important;
        padding: 10px 5px !important;
        border-bottom: 1px solid var(--c-light);
        &.is-open{
          color: var(--accordion-item-header-color);
        }
      }
      .pagination-section{
        justify-content: center;
      }
    }
  .notification-toaster{
    top: 80px;
    right: 20px;
    padding: 1rem 2rem;
    --notification-max-width: 300px;
    position: fixed;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    opacity: 1;
    z-index: 1000;
    display: flex;
    .sf-icon{
      margin-right: 10px;
    }
  }
  .notification-toaster.hide-toaster{
    opacity: 0;
    display: none;
  }
  .sf-pagination__item{
    --pagination-item-height : 38px;
    --pagination-item-width : 38px;
    height: var(--pagination-item-height);
    line-height: var(--pagination-item-height);
    padding: 0 10px;
    --pagination-item-color: var(--c-black);
    --icon-color: var(--color_2, var(--c-text));
    --button-color: var(--color_2, var(--c-text));
    border-radius: 5px;
    &.prev, &.next {
      .sf-button--pure{
        margin-top: 11px;
        transform: scale(1.2);
      }
    }
    &.sf-button--pure{
      border: 1px solid var(--_c-gray-primary-lighten);
    }
    &.current{
      background-color: var(--_c-gray-primary-lighten) !important;
      color: var(--c-white) !important;
    } 
  }
  .sf-button.dmWidget{
    width: 100% !important;
  }
  .sf-chevron__bar:after{
    --chevron-color: var(--color_1);
  }
  .sf-component-select__selected{
    --component-select-color: var(--color_2);
  }
  .sf-heading__title{
    color: var(--color_2, #000) !important;
  }
  .filters__item, .filters__color_label, .sf-filter__count{
    --filter-label-color: var(--color_2);
    color: var(--color_2);
  }
  .sf-checkbox__checkmark{
    --checkbox-border-color: var(--color_1) !important;
  }
  .sf-component-select__options{
    background: var(--color_4, #fff) !important;
  }
}
</style>
