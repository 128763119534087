<template>
  <div id="productList" ref="productList">
    <SfNotification :visible="true" :title="t(toaster.title)" :message="t(toaster.message)" :type="toaster.type"
      persistent="" class="notification-toaster" v-bind:class="{ 'hide-toaster': !toaster.visible }">
      <template #icon>
        <SfIcon :icon="toaster.icon" size="lg" color="white" viewBox="0 0 24 24" :coverage="1" />
      </template>
    </SfNotification>
    <div class="navbar section" v-if="widgetConfig?.showProductFilter != 'never'">
      <div class="navbar__aside desktop-only" v-if="widgetConfig?.showProductFilter == 'always' && dudaConfig?.device != 'mobile'">
        <SfHeading :level="4" :title="t('FILTERS')" class="navbar__title" />
      </div>
      <div class="navbar__main"><!-- v-if="widgetConfig?.showProductFilter" -->
        <div v-if="widgetConfig?.showProductFilter == 'collapsed' || dudaConfig?.device == 'mobile'">
          <SfButton class="sf-button--text navbar__filters-button custom-navbar-label" aria-label="Filters"
            @click="isFilterSidebarOpen = true">
            <SfIcon size="24px" color="#43464E" icon="filter2" class="navbar__filters-icon" />
            <div class="dmNewParagraph">
              <p class="my-0">{{ t('FILTERS') }}</p>
            </div>
          </SfButton>
        </div>
        <div class="navbar__sort desktop-only">
          <span class="navbar__label custom-navbar-label">{{ t('SORT_BY') }}:</span>
          <SfComponentSelect v-model="sortBy" class="navbar__select custom-navbar-value" @change="applySortBy"
            v-if="sortBy">
            <SfComponentSelectOption v-for="option in sortByOptions" :key="option.value" :value="option"
              class="sort-by__option">
              <div class="dmNewParagraph">
                <p class="my-0">{{ t(option.label) }}</p>
              </div>
            </SfComponentSelectOption>
          </SfComponentSelect>
        </div>
        <div class="navbar__counter" v-if="widgetConfig?.showPagination">
          <span class="navbar__label desktop-only custom-navbar-label">{{ t('PRODUCTS_FOUND') }} : </span>
          <span class="desktop-only custom-navbar-value">{{ total }}</span>
          <span class="navbar__label smartphone-only custom-navbar-value">{{ total }} {{ t('ITEMS') }}</span>
        </div>
        <div class="navbar__view">
          <!-- <span class="navbar__view-label desktop-only custom-navbar-label"> {{ t('VIEW') }}</span> -->
          <!-- <SfButton
            class="sf-button--pure navbar__view-button"
            aria-label="Change to grid view"
            :aria-pressed="isGridView"
            @click="isGridView = true"
          >
            <SfIcon
              class="navbar__view-icon"
              :color="'#43464E'"
              icon="tiles"
              size="12px"
            />
          </SfButton> -->
          <!-- <SfButton
            class="sf-button--pure navbar__view-button"
            aria-label="Change to list view"
            :aria-pressed="!isGridView"
            @click="isGridView = false"
          >
            <SfIcon
              class="navbar__view-icon"
              :color="'#43464E'"
              icon="list"
              size="12px"
            />
          </SfButton> -->
        </div>
      </div>
    </div>
    <div class="main section">
      <!-- v-if="!widgetConfig.hideProductFilter || (showFilterSideBar && dudaConfig?.device != 'mobile')" -->
      <!--  v-if="dudaConfig?.device == 'desktop' || widgetConfig.showProductFilter" -->
      <div class="sidebar desktop-only"  v-if="dudaConfig?.device == 'desktop' && widgetConfig.showProductFilter == 'always'">
        <!-- <div class="skeleton" style="width: 100%;height: 100vh;"  v-show="!products.length && isLoading"></div> -->
        <filtersSidebar :token="token" :businessDetails="businessDetails" :openSidebar="isFilterSidebarOpen"
          :dudaConfig="dudaConfig" :widgetConfig="widgetConfig"
          @closeSideBar="isFilterSidebarOpen = false" @categoryList="getCategoryList" @change="setFilters">
        </filtersSidebar>
      </div>
      <!-- <div  >
        <span v-if="displayCategories.length > 0">
          <SfAccordion :show-chevron="true">
            <SfAccordionItem
              v-for="(accordion, i) in displayCategories"
              :key="i"
              :header="accordion.sPropertyOptionValue"
            >
              <template>
                <SfList class="list">
                  <SfListItem
                    v-for="(item, j) in accordion.items"
                    :key="j"
                    class="list__item"
                  >
                    <SfMenuItem :label="item.label" :count="item.count" @click="setCategoryFilter(item)"/>
                  </SfListItem>
                </SfList>
              </template>
            </SfAccordionItem>
          </SfAccordion>
        </span>
      </div> -->

      <!-- <div class="notFound" v-show="!isLoading && products.length == 0">
        <h3>Product not found!</h3>
      </div> -->
      <div class="products">
        <div class="products_grid" v-show="!products.length && isLoading">
          <div class="skeleton" style="width: 250px;height: 380px;" v-for="(i) in 10" :key="i"></div>
        </div>        
        <div v-if="products.length && !isLoading">
          <transition-group v-show="isGridView" appear name="products__slide" tag="div" class="products_grid" :class="{ 'smartphone_grid': dudaConfig?.device != 'desktop'}">
              <SfProductCard v-for="(product, i) in products" :key="product._id" :style="{ '--index': i }"
                :colors="product.colors ? product.colors : []"
                :title="(product.oName && product.oName[language] ? product.oName[language] : 'Unknown')"
                :image="product.aImage && product.aImage.length > 0 ? ( dudaConfig && dudaConfig.config?.onHoverShowImage ? product.aImage : product.aImage[0]) : ''" :link="setProductLink(product)"
                :image-height="dudaConfig?.device == 'desktop' ? 250 : 200" :image-width="dudaConfig?.device == 'desktop' ? 250 : 200"
                :regular-price="product.bHidePrice ? t('PRICE_ON_REQUEST') : getProductPrice(product) | currency(currencyFormatter)"
                :special-price="product.bHidePrice ? '' : calculateSpecialPrice(product) | currency(currencyFormatter)"
                :badge-label="product.bDiscountOnPercentage ? getBadgeLabel(product) : getBadgeLabel(product) | currency(currencyFormatter)"
                :is-in-wishlist="product.isInWishlist" :wishlist-icon="null" :show-add-to-cart-button="false"
                :isAddedToCart="product.addedInCart" image-tag="nuxt-img" :nuxt-img-config="{
                  format: 'webp',
                  fit: 'cover',
                }" class="products__product-card"
                :class="{ 'products__hover_layout': dudaConfig && dudaConfig.config?.onHoverAddtoCartBtn }">
                <template #title="{ title, link }">
                  <SfButton :link="link" class="sf-button--pure sf-product-card__link" data-testid="product-link">
                    <h5 class="text-uppercase sf-product-card__title">
                      {{ title }}
                    </h5>
                  </SfButton>
                </template>
                <template #price="{ specialPrice, regularPrice }">
                  <SfPrice :class="{ 'display-none': !regularPrice }" class="sf-product-card__price" :regular="regularPrice"
                    :special="specialPrice">
                    <template #regular>
                      <h6 :class="{ 'display-none': !regularPrice || specialPrice }" class="sf-price__regular">
                        {{ regularPrice }}
                      </h6>
                    </template>
                    <template #special>
                      <h6 :class="{ 'display-none': !specialPrice }" class="sf-price__special">
                        {{ specialPrice }}
                      </h6>
                    </template>
                  </SfPrice>
                </template>
                <template #image="{ image, title, link, imageHeight, imageWidth }">
                  <SfButton :link="link" class="sf-button--pure sf-product-card__link" data-testid="product-link"
                    aria-label="Go To Product" v-on="$listeners"
                    :style="{ height: `${imageHeight}px`, display: 'flex', margin: 'auto', width: 'auto' }">
                    <template v-if="Array.isArray(image)">
                      <SfImage v-for="(picture, key) in image.slice(0, 2)" :key="key" class="sf-product-card__picture"
                        :src="picture ? picture : ''" :alt="title" :width="imageWidth" :height="imageHeight"
                        :placeholder="productPlaceholder" style="margin: auto;" />
                    </template>
                    <SfImage v-else class="sf-product-card__image" :src="image ? image : ''" :alt="title" :width="imageWidth"
                      :height="imageHeight" :placeholder="productPlaceholder" style="margin: auto;" />
                  </SfButton>
                </template>
                <template #badge="{ badgeLabel }">
                  <SfBadge v-if="product.bBestseller" class="sf-product-card__badge custom-bestseller-badge sf-badge">
                    {{ t('BESTSELLER') }}
                  </SfBadge>
                  <SfBadge v-if="product.bIsFeatured" class="sf-product-card__badge custom-feature-badge sf-badge">
                    {{ t('FEATURED') }}
                  </SfBadge>
                  <SfBadge class="sf-product-card__badge custom-discount-badge sf-badge"
                    :class="[{ 'display-none': !badgeLabel }]">
                    {{ badgeLabel }}
                  </SfBadge>
                </template>
                <template #add-to-cart>
                  <span></span>
                </template>
                <template #reviews>
                  <SfButton class="sf-add-to-cart__button" :class="[{ 'dmWidget': !product.bDisableAddToCart }]"
                    @click="addToCart(product)" :disabled="product.bDisableAddToCart" v-if="product.bShowAddToCartButton">
                    <span class="text">{{ t('BUTTON_ADD_TO_CART') }}</span>
                  </SfButton>
                  <span class="sf-add-to-cart__button" v-if="product.bShowNoStockText">{{
                    t('EXPLAIN_NO_STOCK_BUT_POSSIBLE_TO_ORDER') }}</span>
                  <span class="sf-add-to-cart__button" v-if="product.bShowReserveText">{{
                    t('EXPLAIN_ONLY_RESERVE_POSSIBLE') }}</span>
                </template>
              </SfProductCard>
          </transition-group>
          <transition-group v-show="!isGridView" appear name="products__slide" tag="div" class="products__list">
            <SfProductCardHorizontal v-for="(product, i) in products" :key="product._id" :style="{ '--index': i }"
              :title="(product.oName && product.oName[language] ? product.oName[language] : 'Unknown')"
              :description="product.description ? product.description : ''"
              :image="product.aImage && product.aImage.length > 0 ? ( dudaConfig && dudaConfig.config?.onHoverShowImage ? product.aImage : product.aImage[0]) : ''"
              :regular-price="product.bHidePrice ? t('PRICE_ON_REQUEST') : getProductPrice(product) | currency(currencyFormatter)"
              :special-price="product.bHidePrice ? '' : calculateSpecialPrice(product) | currency(currencyFormatter)"
              :badge-label="product.bDiscountOnPercentage ? getBadgeLabel(product) : getBadgeLabel(product) | currency(currencyFormatter)"
              :link="setProductLink(product)" :is-in-wishlist="product.isInWishlist" :wishlist-icon="null"
              :image-height="dudaConfig?.device == 'desktop' ? 250 : 200" :image-width="dudaConfig?.device == 'desktop' ? 250 : 200"
              image-tag="nuxt-img" :nuxt-img-config="{
                format: 'webp',
                fit: 'cover',
              }" class="products__product-card-horizontal" :isAddedToCart="product.addedInCart"
              @click:add-to-cart="addToCart(product)">
              <template #image="{ image, title, link, imageHeight, imageWidth }">
                <SfButton :link="link" class="sf-button--pure sf-product-card__link" data-testid="product-link"
                  aria-label="Go To Product" v-on="$listeners"
                  :style="{ height: `${imageHeight}px`, display: 'flex', margin: 'auto', width: 'auto' }">
                  <template v-if="Array.isArray(image)">
                    <SfImage v-for="(picture, key) in image.slice(0, 2)" :key="key" class="sf-product-card__picture"
                      :src="picture ? picture : ''" :alt="title" :width="imageWidth" :height="imageHeight"
                      :placeholder="productPlaceholder" style="margin: auto;" />
                  </template>
                  <SfImage v-else class="sf-product-card__image" :src="image ? image : ''" :alt="title" :width="imageWidth"
                    :height="imageHeight" :placeholder="productPlaceholder" style="margin: auto;" />
                </SfButton>
              </template>
              <template #badge="{ badgeLabel }">
                <SfBadge v-if="product.bBestseller" class="sf-product-card__badge custom-bestseller-badge sf-badge">
                  {{ t('BESTSELLER') }}
                </SfBadge>
                <SfBadge v-if="product.bIsFeatured" class="sf-product-card__badge custom-feature-badge sf-badge">
                  {{ t('FEATURED') }}
                </SfBadge>
                <SfBadge class="sf-product-card__badge custom-discount-badge sf-badge"
                  :class="[{ 'display-none': !badgeLabel }]">
                  {{ badgeLabel }}
                </SfBadge>
              </template>
              <!-- <template #configuration>
                <SfProperty
                  class="desktop-only"
                  name="Size"
                  value="XS"
                  style="margin: 0 0 1rem 0"
                />
                <SfProperty class="desktop-only" name="Color" value="white" />
              </template> 
              <template #actions>
                <SfButton
                  class="sf-button--text desktop-only"
                  style="margin: 0 0 1rem auto; display: block"
                  @click="$emit('click:add-to-wishlist')"
                >
                  Save for later
                </SfButton>
                <SfButton
                  class="sf-button--text desktop-only"
                  style="margin: 0 0 0 auto; display: block"
                  @click="$emit('click:add-to-compare')"
                >
                  Add to compare
                </SfButton>
              </template>-->
            </SfProductCardHorizontal>
          </transition-group>
  
          <div class="pagination-section" v-if="widgetConfig?.showPagination">
            <SfPagination class="products__pagination" :current="page" :total="totalPages" :visible="6"
              @click="pageChange" />
            <div class="products__show-on-page desktop-only">
              <span class="products__show-on-page__label">{{ t('SHOW_ON_PAGE') }}:</span>
              <SfSelect class="products__items-per-page" v-model="perPage" @input="getProductList(1)">
                <SfSelectOption v-for="option in showOnPage" :key="option" :value="option"
                  class="products__items-per-page__option">
                  {{ option }}
                </SfSelectOption>
              </SfSelect>
            </div>
          </div>
        </div>
        <div class="no-products-available" v-if="!isLoading && !products.length">
          <SfHeading :title="t('NO_PRODUCTS_FOUND')" :level="3" />
        </div>
      </div>

    </div>
    <!-- || dudaConfig?.device == 'mobile' -->
    <filtersSidebar v-if="(dudaConfig?.device == 'mobile' || dudaConfig?.device == 'desktop') && widgetConfig.showProductFilter == 'collapsed' || dudaConfig?.device == 'mobile' && widgetConfig.showProductFilter == 'always'" :token="token" :businessDetails="businessDetails" :openSidebar="isFilterSidebarOpen"
      :dudaConfig="dudaConfig" :widgetConfig="widgetConfig" :showFilterSideBar="showFilterSideBar" button
      @closeSideBar="isFilterSidebarOpen = false" @categoryList="getCategoryList" @change="setFilters">
    </filtersSidebar>
    <!-- <SfLoader :loading="isLoading" v-show="isLoading" class="customLoader">
    </SfLoader> -->
  </div>
</template>
<script>
import filtersSidebar from './filters-sidebar.vue';
import { store } from '../services/store.js';
import {
  SfButton,
  // SfList,
  SfIcon,
  SfHeading,
  SfProductCard,
  SfProductCardHorizontal,
  SfPagination,
  // SfAccordion,
  SfComponentSelect,
  SfPrice,
  SfSelect,
  SfNotification,
  // SfLoader,
  SfImage,
  SfBadge
} from "@storefront-ui/vue";
export default {
  name: "Category",
  components: {
    SfButton,
    SfIcon,
    // SfList,
    SfProductCard,
    SfProductCardHorizontal,
    SfPagination,
    SfHeading,
    // SfAccordion,
    SfComponentSelect,
    SfPrice,
    SfSelect,
    SfNotification,
    // SfLoader,
    SfImage,
    filtersSidebar,
    SfBadge
  },
  props: {
    businessId: String,
    token: String,
    dudaConfig: {},
    widgetConfig: {},
  },
  data() {
    return {
      requestParams: store.requestParams,
      page: 0,
      total: 0,
      perPage: '30',
      isLoading: true,
      toaster: {
        visible: false,
        title: "NOTIFICATION",
        message: "PRODUCT_ADDED_INTO_CART",
        type: "danger",
        icon: "error"
      },
      coreApiUrl: store.coreApiUrl,
      cashRegisterApiUrl: store.cashRegisterApiUrl,
      isFilterSidebarOpen: false,
      isGridView: true,
      isBackFromDetails: localStorage.getItem('isBackFromDetails'),
      sortByOptions: store.sortByOptions,
      sortBy: false,
      showOnPage: ['20', '30', '40', '60'],
      products: [],
      categoryList: [],
      businessDetails: {},
      language: this.dudaConfig?.locale || 'en',
      route: window.location.hash,
      productPlaceholder: "https://prismanote.s3.amazonaws.com/websitebuilder-default-templates/image-website-builder/no-photo-available.jpg",
      supportedCurrencies: store.supportedCurrencies,
      selectedCurrency: store.selectedCurrency,
      // showFilterSideBar: true,
      navDetailsExist: false,
      currencyFormatter: undefined,
      seoCollection: []
    };
  },
  created() {
    store.$on('sortByChanged', (value) => {
      this.sortBy = value;
    });
    
    // store.$on('widgetConfigSet', () => {
    //   this.widgetConfig = store.widgetConfig;
    //   console.log('product list -> received widgetConfigSet event this.widgetConfig', store.jps(this.widgetConfig))
    // });
      
      store.$on('requestParamsChanged', () => {
        this.requestParams = store.requestParams;
        // console.log('product list -> received requestParamsChanged event calling getProductList', store.jps(this.requestParams.oFilterBy))
        this.getProductList(this.page);
      })
      
      store.$on('change', () => {
        this.requestParams = store.requestParams;
        // console.log('product list -> received change event calling getProductList', store.jps(this.requestParams.oFilterBy))
        this.getProductList(this.page);
      })
      
      store.$on('categoryList', (categoryList) => {
      this.categoryList = categoryList;
    });

  },
  async mounted() {
    // console.log('productlist mounted') //, store.jps(store.widgetConfig)
    // this.getProductList(1)
    let pageNo = parseInt(this.route.replace("#", ''));
    this.page = !isNaN(pageNo) ? pageNo : 1;
    localStorage.removeItem('isBackFromDetails');

    await this.fetchBusinessLocationList();

    // console.log('product list -> calling store.getFiltersList')
    await store.getFiltersList();

    // console.log('product list -> calling store.setDefaultFilters')
    store.setDefaultFilters();

    await store.fetchCategoryPropertyList();

    this.handleHashChange();
    // this.showFilterSideBar = this?.dudaConfig?.productLayout == 'withFilter' ? false : true;
    // this.showFilterSideBar = this?.widgetConfig?.showProductFilter;
    const navLinks = await window?.dmAPI?.getNavItemsAsync();
    this.navDetailsExist = navLinks?.filter(n => {
      if (n.alias == 'product/~page-item~') {
        return true;
      } else if (n.subNav && n.subNav.length > 0) {
        let navDetail = n.subNav.filter(nav => nav.alias == 'product/~page-item~');
        return navDetail.length > 0 ? true : false;
      } else {
        return false;
      }
    })?.length ? true : false;

    this.seoCollection = await window?.dmAPI?.getCollection({collectionName: 'SEOCollection-1'}) || [];
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / parseInt(this.perPage));
    },
    // displayCategories(){
    //   return this.categoryList.filter((details) => details.sPropertyName === "Category");
    // }
  },
  methods: {
    getCategoryList(event) {
      if (this.categoryList.length == 0) {
        this.categoryList = event.map((detail) => {
          if (detail.sPropertyName === "Category") {
            detail['items'] = [{
              label: 'All',
              ...detail
            }];
          }
          return detail;
        });
      }
    },
    getProductList(skip) {
      this.isLoading = true;
      // console.log("Fetching products...");
      // console.log('getProductList',{skip}, JSON.parse(JSON.stringify(store.requestParams)));
      this.products = [];
      this.total = 0;
      this.perPage = !store.widgetConfig?.showPagination ? (store.widgetConfig?.noProductOnPage)?.toString() : this.perPage.toString();
      this.perPage = this.perPage || '30';
      this.page = skip;
      store.requestParams.skip = parseInt(this.perPage) * (skip - 1);
      store.requestParams.limit = parseInt(this.perPage);
      store.requestParams["aProjection"] = ["oName", "sEan", "sProductNumber", "nPriceIncludesVat", "bDiscountOnPercentage", "nDiscount", "aImage", "sArticleNumber", "nHighestPrice"];
      store.requestParams.oFilterBy.aCategory = store.aCategoryFilters;
      store.requestParams.isFrom = "assortment";
      if(this.$cookies.get('isBackFromDetails') && this.$cookies.get('filtersData')){
        let filterData = this.$cookies.get('filtersData');
        if(filterData)store.requestParams.oFilterBy = filterData;
        this.$cookies.remove('isBackFromDetails');
        this.$cookies.remove('filtersData');
        this.setSelectedFilters(filterData);
      }
      this.$cookies.set('filtersData', store.requestParams.oFilterBy);
      // store.requestParams.iLocationId = this.businessDetails?.webLocation?._id;
      // const headers = { headers: { Authorization: this.token } }
      this.axios.post(`${this.coreApiUrl}/api/v1/business/products/list`, store.requestParams, store.headers).then((result) => {
        result = result.data;
        this.isLoading = false;
        if (result?.data?.length && result?.data[0]?.result?.length) {
          this.products = result.data[0].result;
          const bAddToCartCondition1 = this.dudaConfig?.config?.addToCartBtn;
          this.products.forEach((product) => {
            product.maxStock = product.aLocation.reduce((acc, curr) => acc + (curr?.nStock || 0), 0);
            this.processWebshopSettings(product, bAddToCartCondition1);
            // product.bShowAddToCartButton = product.maxStock > 0 && !product.bHidePrice
          });
          // console.log(this.products)
          this.total = result.data[0].count.totalData;
          let height = this.$refs.productList.scrollHeight + 3400;
          if (this.isBackFromDetails) {
            this.isBackFromDetails = false;
            let scrollPos = localStorage.getItem('recentScrollPos');
            if (scrollPos && height > scrollPos) {
              localStorage.removeItem('recentScrollPos');
              setTimeout(() => {
                window.scrollTo(100, scrollPos);
              }, 1000);
            }
          } else if (window.document.scrollingElement.scrollTop > 300) {
            setTimeout(() => {
              window.scrollTo(0, 100);
            }, 1000);
          }
        }else{
          this.isLoading = false;
        }
      }).catch(() => {
        this.isLoading=false
      });
    },
    processWebshopSettings(product, bAddToCartCondition1) {
      product.bShowAddToCartButton = true;
      product.bDisableAddToCart = false;
      product.bShowNoStockText = false;
      product.bShowReserveText = false;

      if (!bAddToCartCondition1) {
        product.bShowAddToCartButton = false;
      } else if (product?.eWebshopSetting) {
        switch (product.eWebshopSetting) {
          case 'do-not-show':
            product.bShowAddToCartButton = false;
            break;
          case 'show-with-stock':
            if (!product.maxStock) {
              product.bDisableAddToCart = true;
            }
            break;
          case 'show-without-stock':
            if (!product.maxStock) {
              product.bShowNoStockText = true;
              product.bShowAddToCartButton = !this.dudaConfig.config?.noStockHideAddToCart;
            }
            break;
          case 'reserve-only-show-price':
            product.bShowAddToCartButton = false;
            product.bShowReserveText = true;
            break;
          case 'reserve-only-no-price':
            product.bShowAddToCartButton = false;
            product.bHidePrice = true;
            break;
        }
      }
    },
    pageChange(start) {
      window.history.pushState({}, null, '#' + start);
      // console.log('calling getProductList from pageChange', {start})
      this.getProductList(start);
    },
    calculateSpecialPrice(product) {
      product.nPriceIncludesVat = this.getProductPrice(product);
      return product.nDiscount > 0 ? (product.nPriceIncludesVat -
        (product.bDiscountOnPercentage ?
          ((product.nPriceIncludesVat * product.nDiscount) / 100)
          : product.nDiscount
        ).toFixed(2)) : null;
    },

    getBadgeLabel(product) {
      return product.nDiscount > 0 ? (
        product.nDiscount + (product.bDiscountOnPercentage ? '%' : '')
      ) : null;
    },
    applySortBy(event) {
      store.requestParams.sortBy = event.sortBy;
      store.requestParams.sortOrder = event.sortOrder;
      // console.log('calling getProductList from applySortBy with arg 1', { event })
      this.getProductList(1);
    },

    addToCart(product) {
      if(!product.nPriceIncludesVat){
        this.toaster.message = "AN_ERROR_OCCURED";
        this.toaster.type = "danger";
        this.toaster.icon = "added_to_cart";
        this.toaster.visible = true;
        setTimeout(() => {
          this.toaster.visible = false;
        }, 3000);
        return
      }
      const oBody = {
        iBusinessProductId: product._id,
        iBusinessId: store.requestParams.iBusinessId,
        iLocationId: this.businessDetails.webLocation._id,
        nQuantity: 1,
        nProductSize: '',
        sIsFrom: '',
        bSaveForLater: false,
      }

      if (localStorage.getItem('customer') && localStorage.getItem('customer') != '') {
        oBody.iCustomerId = localStorage.getItem('customer');
      }
      if (localStorage.getItem('cart') && localStorage.getItem('cart') != '') {
        oBody.iCartId = localStorage.getItem('cart');
      }
      this.axios.post(`${this.cashRegisterApiUrl}/api/v1/cart-products/create`, oBody, store.headers).then(response => {
        this.isLoading = false;
        // console.log(response);
        if (response?.data?.data) {
          let cartElements = window.document.getElementsByClassName("cart-product-count");
          Object.keys(cartElements).forEach((el) => {
            if (cartElements[el] && cartElements[el].getBoundingClientRect()?.x > 0) {
              cartElements[el].innerHTML = parseInt(cartElements[el].innerHTML) + 1;
            }
          });
          if (!oBody.iCartId) {
            localStorage.setItem('cart', response.data.data.iCartId);
          }
          this.toaster.message = "PRODUCT_ADDED_INTO_CART";
          this.toaster.type = "success";
          this.toaster.icon = "added_to_cart";
          product.addedInCart = true;
        } else {
          this.toaster.type = "danger"
          this.toaster.icon = "added_to_cart";
        }
        this.toaster.visible = true;
        setTimeout(() => {
          this.toaster.visible = false;
        }, 3000);
      }).catch(() => {
        this.toaster.message = "PRODUCT_MAY_ALREADY_EXIST_IN_CART";
        this.toaster.type = "danger";
        this.toaster.icon = "added_to_cart";
        this.toaster.visible = true;
        setTimeout(() => {
          this.toaster.visible = false;
        }, 3000);
      });
    },
    setFilters() {
      // console.log('setFilters called -> now calling product list');
      this.getProductList(1);
    },
    setCategoryFilter(filter) {
      store.requestParams.oFilterBy.oDynamic[filter.iPropertyId] = {}
      store.requestParams.oFilterBy.oDynamic[filter.iPropertyId][filter.sPropertyOptionKey] = filter.sPropertyOptionValue;
      // console.log('calling getProductList from setCategoryFilter with arg 1', { filter })
      this.getProductList(1);
    },
    async fetchBusinessLocationList() {
      let apiUrl = `${this.coreApiUrl}/api/v1/business/${store.requestParams.iBusinessId}/list-location`;
      let headers = {
        headers: { Authorization: this.token }
      }
      return this.axios.post(apiUrl, {}, headers).then((response) => {
        if (response?.data?.data) {
          this.businessDetails = response.data.data;
          this.businessDetails.webLocation = this.businessDetails.aLocation.find((location) => location.bIsWebshop);
          this.businessDetails.aLocation = this.businessDetails.aLocation.filter((location) => !location.bIsWebshop);
          const selectedCurrency = store.supportedCurrencies.find(currency => currency.value == this.businessDetails.webLocation.eCurrency);
          store.selectedCurrency = selectedCurrency?.symbol || '€';
          this.currencyFormatter = new Intl.NumberFormat(store.language, { style: 'currency', currency: selectedCurrency?.code || 'EUR' })
        }
      });
    },
    getProductPrice(product) {
      if (product.nHighestPrice) {
        if(!parseFloat((product.nHighestPrice).toFixed(2))){
          product.bShowAddToCartButton = false;
          product.bShowReserveText = false;
        }
        return parseFloat((product.nHighestPrice).toFixed(2));
      }
      let location = product.aLocation.filter((location) => location._id == this.businessDetails?.webLocation?._id);
      if(!parseFloat((location[0]?.nPriceIncludesVat)?.toFixed(2))){
        product.bShowAddToCartButton = false;
        product.bShowReserveText = false;
      }
      return location.length > 0 ? parseFloat((location[0].nPriceIncludesVat).toFixed(2)) : 0;
    },
    getLog(product) {
      console.log(product);
      return '1';
    },
    handleHashChange() {
      addEventListener('hashchange', () => {
        this.route = window.location.hash;
        let pageNo = parseInt(this.route.replace("#", ''));
        this.page = !isNaN(pageNo) ? pageNo : 1;
        // console.log('calling get product list from handle hash change with this.page=', this.page)
        this.getProductList(this.page);
      });
    },
    setProductLink(product) {
      let productNameSlug = product.oNameSlug && product.oNameSlug[store.language] ? product.oNameSlug[store.language] : product.sProductNumber;
      let seoDetails = this.seoCollection.filter((details) => details.page_item_url == productNameSlug);
      if (this.navDetailsExist && seoDetails?.length > 0) {
        return `/product/${productNameSlug}`;
      } else {
        return `/details?id=${product._id}`;
      }
    },
    setSelectedFilters(filtersData){
      Object.keys(filtersData).forEach( (keyName) => {
        if(keyName == 'aCategory'){
          store.oldFiltersList = filtersData.aCategory;
          filtersData.aCategory.forEach(
            (category) => {
              this.setSelectedCategoryFilter(category);
            }
          );
          setTimeout(()=> {
            store.oldFiltersList = []
          }, 500);
        } else if(keyName != 'aCategory' && keyName != 'bFilterOutOnWebShop'){
          let keyValue = '';
          let indexValue = '';
          const fIndex = store.filtersList.findIndex((filter) => {
            const params = filter.sRequestFormat?.split(":");
            keyValue = params[0].replace(/[^a-zA-Z0-9_]/g, '');
            indexValue = params[1].replace(/[^a-zA-Z0-9_]/g, '');
            return !filter.bIsDynamic && keyValue == keyName;
          });
          if(fIndex > -1 && filtersData[keyName].length > 0){
            if(store.filtersList[fIndex].aOptions.length > 0){
              store.filtersList[fIndex].aOptions = store.filtersList[fIndex].aOptions.map(
                (option) =>{
                  if(filtersData[keyName].includes(option[indexValue])){
                    option.selected = true;
                  }
                  return option;
                }
              )
            }
          }
        }
      });
    },
    setSelectedCategoryFilter(property){
      const fIndex = store.filtersList.findIndex((f) => f.iPropertyId === property.iPropertyId);
      if(fIndex > -1){
        store.filtersList[fIndex].aOptions = store.filtersList[fIndex].aOptions.map(
          (fObj) => {
            if(fObj._id == property.iPropertyOptionId || 
              (property.aPropertyOption?.length > 0 && property.aPropertyOption.findIndex(pOption => pOption.iPropertyOptionId == fObj._id) > -1)
            ){
              fObj.selected = true;
              store.filtersList[fIndex].setSubProperties = true;
              store.onChangeFilter(true, fObj, store.filtersList[fIndex]);
              store.selectedFilters.push(fObj);
            }
            return fObj;
          }
        );
      }
    }
  },
  filters: {
    currency: function (value, currencyFormatter) {
      if (!value || isNaN(value) || !currencyFormatter) return value;
      return currencyFormatter.format(value);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/vue/styles";

#dm .dmRespRow.fullBleedMode {
  .products{
    width: 98%;
  }
  .products__product-card {
    @include for-desktop {
      --product-card-max-width: var(--c-product-card-max-width, 250px) !important;
    }

    @include for-mobile {
      --product-card-max-width: 160px !important;
    }
  }
}

#productList {
  box-sizing: border-box;

  @include for-desktop {
    // max-width: 1240px;
    margin: 0 auto;
  }
}

.main {
  display: flex;

  &.section {
    padding: var(--spacer-xs);

    @include for-desktop {
      padding: 0;
    }
  }
}

.navbar {
  position: relative;
  display: flex;
  border: 1px solid var(--c-light);
  border-width: 0 0 1px 0;

  @include for-desktop {
    border-width: 1px 0 1px 0;
  }

  &.section {
    padding: var(--spacer-sm);

    @include for-desktop {
      padding: 0;
    }
  }

  &__aside,
  &__main {
    display: flex;
    align-items: center;
    padding: var(--spacer-sm) 0;
  }

  &__aside {
    flex: 0 0 15%;
    padding: var(--spacer-sm) var(--spacer-sm);
    border: 1px solid var(--c-light);
    border-width: 0 1px 0 0;
  }

  &__main {
    flex: 1;
    display: flex;
    padding: 0;

    @include for-desktop {
      padding: var(--spacer-xs) var(--spacer-xl);
    }
  }

  &__title {
    --heading-title-font-weight: var(--font-weight--semibold);
    // --heading-title-font-size: var(--h3-font-size);
  }

  &__filters-icon {
    margin: 0 0 0 var(--spacer-2xs);
    order: 1;

    @include for-desktop {
      margin: 0 var(--spacer-xs) 0 0;
      order: 0;
    }
  }

  &__filters-button {
    display: flex;
    align-items: center;
    --button-font-size: var(--font-size--base);
    --button-text-decoration: none;
    --button-color: var(--c-link);
    --button-font-weight: var(--font-weight--normal);

    @include for-mobile {
      --button-font-weight: var(--font-weight--medium);
      margin-right: var(--spacer-sm);
      order: 1;
    }

    svg {
      fill: var(--c-text-muted);
      transition: fill 150ms ease;
    }

    &:hover {
      svg {
        fill: var(--c-primary);
      }
    }
  }

  &__label {
    font-family: var(--font-family--secondary);
    font-weight: var(--font-weight--normal);
    color: var(--c-text-muted);

    @include for-desktop {
      margin: 0 var(--spacer-2xs) 0 0;
    }
  }

  &__select {
    --component-select-width: 220px;
    --component-select-padding: 0;
    --component-select-selected-padding: 0 var(--spacer-lg) 0 var(--spacer-2xs);
    --component-select-margin: 0;
    --component-select-error-message-height: 0;
  }

  &__sort {
    display: flex;
    align-items: center;
    margin: 0 auto 0 var(--spacer-2xl);
  }

  &__counter {
    font-family: var(--font-family--secondary);
    margin: auto;

    @include for-desktop {
      margin: auto 0 auto auto;
    }
  }

  &__view {
    display: flex;
    order: -1;
    align-items: center;
    margin: 0;

    @include for-desktop {
      margin: 0 0 0 var(--spacer-2xl);
      order: 0;
    }

    &-icon {
      cursor: pointer;
      margin: 0 var(--spacer-base) 0 var(--spacer-sm);

      @include for-desktop {
        margin: 0 var(--spacer-base) 0 0;
      }

      &:last-child {
        margin: 0;
      }
    }

    &-button {
      cursor: pointer;
      margin: 0 var(--spacer-base) 0 var(--spacer-sm);

      @include for-desktop {
        margin: 0 var(--spacer-base) 0 0;
      }

      &:last-child {
        margin: 0;
      }
    }

    &-label {
      margin: 0 var(--spacer-sm) 0 0;
      font: var(--font-weight--normal) var(--font-size--base) / 1.6 var(--font-family--secondary);
      text-decoration: none;
      color: var(--c-link);
    }
  }
}

.sort-by {
  --component-select-dropdown-z-index: 1;
  flex: unset;
  width: 11.875rem;
}

.sidebar {
  flex: 0 0 15%;
  padding: var(--spacer-sm);
  border: 1px solid var(--c-light);
  border-width: 0 1px 0 0;
}

.sidebar-filters {
  --sidebar-title-display: none;
  --sidebar-top-padding: 0;

  @include for-desktop {
    --sidebar-content-padding: 0 var(--spacer-xl);
    --sidebar-bottom-padding: 0 var(--spacer-xl);
  }
}

.list {
  --menu-item-font-size: var(--font-size--sm);

  &__item {
    &:not(:last-of-type) {
      --list-item-margin: 0 0 var(--spacer-sm) 0;
    }
  }
}

.products {
  box-sizing: border-box;
  flex: 1;
  margin: 0;

  &__grid,
  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__grid {
    justify-content: center;
  }

  &__product-card {
    --product-card-max-width: 11rem;
    --product-card-title-margin: var(--spacer-2xs) 0 0 0;
    --price-regular-font-line-height: 1;
    margin-bottom: var(--spacer-sm);

    ::v-deep .sf-product-card__price {
      margin: var(--spacer-sm) 0 var(--spacer-xs);
      justify-content: center;
    }

    ::v-deep .sf-product-card__add-button {
      padding: 0;
      margin-left: auto;
    }

    ::v-deep .sf-product-card__image,
    ::v-deep .sf-product-card__picture {
      top: initial;
    }

    ::v-deep .sf-product-card__link {
      display: inline;
    }

    flex: 1 1 50%;

    @include for-desktop {
      margin-bottom: 0;
      --product-card-max-width: 50%;
      --product-card-title-margin: var(--spacer-base) 0 0 0;
    }
  }

  &__product-card-horizontal {
    margin-bottom: var(--spacer-sm);
    border: 1px solid var(--_c-light-primary);
    flex: 0 0 100%;

    ::v-deep .sf-product-card-horizontal__wishlist-icon {
      .sf-icon {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    ::v-deep .sf-product-card-horizontal__title {
      margin: 10px 0 0 !important;
    }
  }

  &__slide-enter {
    opacity: 0;
    transform: scale(0.5);
  }

  &__slide-enter-active {
    transition: all 0.1s ease;
    transition-delay: calc(0.1s * var(--index));
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin: var(--spacer-base) 0;
  }

  @include for-desktop {
    margin: var(--spacer-sm) var(--spacer-sm);

    &__pagination {
      margin: 0 auto;
    }

    &__product-card-horizontal {
      margin: var(--spacer-xs) 0;
    }

    &__product-card {
      flex: 1 1 25%;
    }

    &__list {
      margin: 0 0 0 var(--spacer-sm);
    }
  }

  &__show-on-page {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;

    &__label {
      font-family: var(--font-family--secondary);
      font-size: var(--font-size--sm);
    }
  }

  &__hover_layout {
    @include for-desktop {
      .sf-add-to-cart__button {
        display: none !important;
      }
    }

    &:hover {
      @include for-desktop {
        .sf-add-to-cart__button {
          display: block !important;
        }

        .sf-product-card__image-wrapper .sf-product-card__link {
          height: 188px !important;
          width: fit-content !important;

          .sf-image--wrapper {
            --image-width: 188px !important;
            --image-height: 188px !important;
          }
        }
      }
    }
  }
}

.pagination-section {
  display: flex;
  margin-top: 10px;
}

.no-products-available {
  --heading-title-color: var(--c-primary);
  --heading-title-margin: 0 0 var(--spacer-base) 0;
  --heading-description-margin: 0 0 var(--spacer-xl) 0;
  --heading-title-font-weight: var(--font-weight--semibold);
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  margin-top: var(--spacer-2xl);

  &__image {
    --image-width: 13.1875rem;
    margin: var(--spacer-2xl) 0;
  }

  @include for-desktop {
    &__image {
      --image-width: 22rem;
    }

    &__button {
      --button-width: 20.9375rem;
    }
  }
}

.customLoader .sf-loader__overlay {
  margin-top: 30%;

  svg {
    transform: scale(3);
  }
}

.sf-product-card__image-wrapper .sf-product-card__badge:nth-child(4) {
  top: 15px !important;
}

.sf-product-card__image-wrapper .sf-product-card__badge:nth-child(5) {
  top: 40px !important;
}
.sf-quantity-selector {
  &__button {
    --button-padding: var(--spacer-2xs) var(--spacer-xs) 0 !important;
  }
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.skeleton {
  background-color: #f0f0f0; /* Placeholder background color */
  animation: loading 1.5s infinite; /* Add loading animation */
  margin-bottom: 1rem;
  margin-right: 1rem;
}

@keyframes loading {
  0% {
    opacity: 0.6; /* Adjust opacity to control the shimmer effect */
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.products_grid{
  display: grid;
  
  gap: 20px; /* Adjust the gap between product cards */
  text-align: center;
  &.smartphone_grid{
    grid-template-columns: repeat(auto-fill, minmax(165px, 165px)); /* Responsive grid with minimum column width of 150px */
    gap: 10px !important;
  }
  &:not(.smartphone_grid){
    grid-template-columns: repeat(auto-fill, minmax((var(--c-product-card-width, 250px), 1fr))); /* Responsive grid with minimum column width of 250px */
  }
}


/* Basic styling for the product grid */
.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Responsive grid with minimum column width of 250px */
  gap: 20px; /* Adjust the gap between product cards */
}

/* Styling for individual product cards */
.product-card {
  padding: 10px;
}

/* Media query for responsiveness */
@media screen and (max-width: 768px) {
  .product-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust column width for smaller screens */
  }
  .sf-product-card {
    max-width: 100%;
  }

  .skeleton{
    width: 100% !important;
  }

  .sf-image--wrapper{
    left: auto;
  }

}

.text-uppercase{
  text-transform: uppercase;
}

</style>