import Vue, { ref } from 'vue';
export const store = new Vue({
    // coreApiUrl : localStorage.getItem('ngrokUrl'),
    data() {
        return {
            token: '',
            headers: {},
            coreApiUrl: 'https://core.1-retailgear.org',
            // coreApiUrl: localStorage.getItem('ngrokUrl'),
            cashRegisterApiUrl: 'https://cash.2-retailgear.org',
            sortByOptions: [
                {
                    sortBy: "nSortingNumber",
                    sortOrder: "",
                    label: "-"
                },
                {
                    sortBy: "dCreatedDate",
                    sortOrder: "descend",
                    label: "LAST_ADDED"
                },
                {
                    sortBy: "nPriceIncludesVat",
                    sortOrder: "",
                    label: "PRICE_FROM_LOW_TO_HIGH"
                },
                {
                    sortBy: "nPriceIncludesVat",
                    sortOrder: "descend",
                    label: "PRICE_FROM_HIGH_TO_LOW"
                },
                {
                    sortBy: "sProductNumber",
                    sortOrder: "descend",
                    label: "PRODUCT_NUMBER"
                }
            ],
            supportedCurrencies: [
                { key: 'EURO', value: 'euro', symbol: '€', code: 'EUR' },
                { key: 'SWISS', value: 'swiss', symbol: 'CHF', code: 'CHF' },
                { key: 'POUND', value: 'pound', symbol: '£', code: 'GBP' }
            ],
            sortBy: "nSortingNumber",
            selectedCurrency: '',
            language: this.dudaConfig?.locale || 'en',
            requestParams: {
                "iBusinessId": "",
                "skip": 0,
                "limit": 40,
                "sortBy": "nSortingNumber",
                "sortOrder": "",
                "searchValue": "",
                "oFilterBy": { bFilterOutOnWebShop: false },
                "aBusinessId": []
            },
            widgetConfig: {},
            currencyFormats: [
                { symbol: '€', currency: 'EUR', stringFormat: 'de-DE' },
                { symbol: 'CHF', currency: 'CHF', stringFormat: 'fr-CH' },
                { symbol: '£', currency: 'GBP', stringFormat: 'en-GB' },
            ],
            selectedFilters: [],
            filtersList: ref([]),

            dynamicPropertyList: ref([]),
            categoryFiltersList: [],
            oldFiltersList: [],
            aCategoryFilters: []
        }
    },
    methods: {
        setToken(publicToken) {
            this.token = publicToken;
            this.headers = { headers: { Authorization: publicToken } };
        },
        setDefaultFilters() {
            // console.log('store setDefaultFilters called', JSON.parse(JSON.stringify(this.widgetConfig)))
            if (this.widgetConfig?.filterBy) {
                switch (this.widgetConfig.filterBy) {
                    case 'latest_added':
                        this.requestParams.sortBy = "dCreatedDate";
                        this.requestParams.sortOrder = "descend";
                        this.sortBy =this.sortByOptions.find(option => option.sortBy === 'dCreatedDate')?.sortBy;
                        break;
                    case 'shop_product_number':
                        this.requestParams.sortBy = "sProductNumber";
                        this.requestParams.sortOrder = "descend";
                        this.sortBy = this.sortByOptions.find(option => option.sortBy === 'sProductNumber')?.sortBy;
                        break;
                    case 'price_asc':
                        this.requestParams.sortBy = "nPriceIncludesVat";
                        this.requestParams.sortOrder = "";
                        this.sortBy = this.sortByOptions.find(option => option.label === 'PRICE_FROM_LOW_TO_HIGH')?.sortBy;
                        break;
                    case 'price_desc':
                        this.requestParams.sortBy = "nPriceIncludesVat";
                        this.requestParams.sortOrder = "descend";
                        this.sortBy = this.sortByOptions.find(option => option.label === 'PRICE_FROM_HIGH_TO_LOW')?.sortBy;
                        break;
                    default: 
                        this.requestParams.sortBy = "nSortingNumber";
                        this.requestParams.sortOrder = "";
                        this.sortBy = this.sortByOptions[0];
                        break;
                }
            } else {
                this.requestParams.sortBy = "nSortingNumber";
                this.sortBy = this.sortByOptions[0].sortBy;
            }
            this.$emit('sortByChanged', this.sortBy);

            if (this.widgetConfig?.gender?.length > 0) {
                this.requestParams.oFilterBy.eGender = this.widgetConfig.gender;
            }
            if (this.widgetConfig?.productFilter && this.widgetConfig?.productFilter != null) {
                // if (this.widgetConfig.productFilter == 'nDiscount') {
                //     this.requestParams.oFilterBy.nDiscount = { $gt: 0 };
                // } else if (this.widgetConfig.productFilter == 'bBestseller' || this.widgetConfig.productFilter == 'bIsFeatured') {
                    this.requestParams.oFilterBy.aProducts = {};
                    this.requestParams.oFilterBy.aProducts[this.widgetConfig.productFilter] = true;
                // }
            }
            if (this.widgetConfig?.collection?.length) {
                this.requestParams.oFilterBy.iArticleGroupId = this.widgetConfig.collection;
                this.widgetConfig.collection.forEach((filter) => {
                    this.selectedFilters.push({ _id: filter });
                });
            }
            if (this.widgetConfig?.brands?.length) {
                this.requestParams.oFilterBy.iBusinessBrandId = this.widgetConfig.brands;
                this.widgetConfig.brands.forEach((filter) => {
                    this.selectedFilters.push({ _id: filter });
                });
            }
            // this.processCategoryOld();
            // this.processCategory();

            // console.log('in store emiting requestParamsChanged');
            // this.$emit('requestParamsChanged');
        },
        //Is this method needed? Can't we just call the setDefaultFilters?
        resetDefaultFilters() {
            //console.log('resetDefaultFilters called', this.widgetConfig)
            this.dynamicPropertyList = ref([]);
            this.selectedFilters = [];
            if (this.widgetConfig?.gender?.length > 0) {
                this.requestParams.oFilterBy.eGender = this.widgetConfig.gender;
            }
            if (this.widgetConfig?.productFilter) {
                // if (this.widgetConfig.productFilter == 'nDiscount') {
                //     this.requestParams.oFilterBy.nDiscount = { $gt: 0 };
                // } else if (this.widgetConfig.productFilter == 'bBestseller' || this.widgetConfig.productFilter == 'bIsFeatured') {
                    this.requestParams.oFilterBy.aProducts = {};
                    this.requestParams.oFilterBy.aProducts[this.widgetConfig.productFilter] = true;
                // }
            }
            this.setDefaultFilters();
            // console.log(JSON.parse(JSON.stringify(this.requestParams.oFilterBy.aConditionDynamicProperty)))
            this.$emit('requestParamsChanged');
        },
        processCategoryOld() {
            if (this.widgetConfig?.category?.length) {
                this.widgetConfig.category.filter((category) => {
                    let categoryObject = {
                        iPropertyId: category._id,
                        oProperty: {}
                    }
                    categoryObject.oProperty[category.sKey] = category.value;
                    if (this.widgetConfig?.subCategory?.length > 0) {
                        let subCategoryList = this.widgetConfig.subCategory.filter((subCategory) => subCategory.oParentField.sName == category.sPropertyName && subCategory.oParentField.value == category.value);
                        subCategoryList.forEach((subCategory) => {
                            let subCategoryObject = {
                                iPropertyId: subCategory._id,
                                oProperty: {}
                            }
                            subCategoryObject.oProperty[subCategory.sKey] = subCategory.value;
                            let subTypeList = this.widgetConfig?.subTypeCategory?.filter((subType) => subType.oParentField.sName == subCategory.sPropertyName && subType.oParentField.value == subCategory.value) || [];
                            subTypeList.forEach((subType) => {
                                let subTypeObject = {
                                    iPropertyId: subType._id,
                                    oProperty: {}
                                }
                                subTypeObject.oProperty[subType.sKey] = subType.value;
                                this.requestParams.oFilterBy.aConditionDynamicProperty.push([categoryObject, subCategoryObject, subTypeObject]);
                            });
                            if (!subTypeList.length) {
                                this.requestParams.oFilterBy.aConditionDynamicProperty.push([categoryObject, subCategoryObject]);
                            }
                        });
                        if (!subCategoryList.length) {
                            this.requestParams.oFilterBy.aConditionDynamicProperty.push([categoryObject]);
                        }
                    } else {
                        this.requestParams.oFilterBy.aConditionDynamicProperty.push([categoryObject]);
                    }
                });
            }
        },
        processCategory() {
            // console.log('processCategory called', JSON.parse(JSON.stringify(this.requestParams.oFilterBy.aConditionDynamicProperty)))
            if (this.widgetConfig?.category?.length) {
                this.requestParams.oFilterBy.aCategory = [];
                // this.processProperties();
                this.widgetConfig.category.forEach(category => {
                    let categoryObject = this.jps(category)

                    if (this.widgetConfig?.subCategory?.length) {
                        let aSubCategoryList = this.widgetConfig.subCategory.filter(sub => 
                            sub.oParentField?.sName == category.property?.sPropertyName && 
                            sub.oParentField?.value == category.property?.sPropertyOptionName 
                        );
                        if(aSubCategoryList.length > 0){
                            aSubCategoryList = aSubCategoryList.map( subCategory => {
                                if (this.widgetConfig?.aGrandCategory?.length) {
                                    let aGrandCategoryList = this.widgetConfig.aGrandCategory.filter(grand => 
                                        grand.oParentField?.sName == subCategory.property?.sPropertyName && 
                                        grand.oParentField?.value == subCategory.property?.sPropertyOptionName 
                                    );
                                    aGrandCategoryList = aGrandCategoryList.map( grandCategory => {
                                        delete grandCategory.oParentField;
                                        return grandCategory;
                                    });
                                    if(aGrandCategoryList?.length > 0){
                                        subCategory['aGrandCategoryList'] = aGrandCategoryList;
                                    }
                                }
                                delete subCategory.oParentField;
                                return subCategory;
                            })
                            categoryObject['aSubCategory'] = aSubCategoryList;
                        }
                    }

                    this.requestParams.oFilterBy.aCategory?.push(categoryObject);
                });
            }
        },
        processProperties() {
            this.widgetConfig?.category.forEach(oCategory => {
                if (this.widgetConfig?.subCategory?.length) {
                    this.widgetConfig?.subCategory.forEach(oSubCategory => {
                        if (oSubCategory.oParentField?.sName == oCategory.sPropertyName && oSubCategory.oParentField?.value == oCategory.value) {
                            oSubCategory.iParentPropertyId = oCategory._id;
                        }
                    });
                }
            })
        },
        setWidgetConfig(widgetData) {
            this.widgetConfig = widgetData;
            // console.log(JSON.parse(JSON.stringify(widgetData)));
            this.language = this.language || 'en';
            this.$emit('widgetConfigSet');

        },
        async getFiltersList() {
            let self = this;
            const oBody = {
                oFilterBy: {
                    webshop: true
                },
                iBusinessId: this.requestParams.iBusinessId
            }
            this.axios.post(`${this.coreApiUrl}/api/v1/product/filters/list`, oBody, this.headers).then(async (response) => {
                if (response?.data?.data?.length) {
                    this.filtersList = response.data.data;
                    for(let filter of this.filtersList) {
                        // console.log('processing filter', filter);
                        filter.selected = ""
                        if (filter.bIsDynamic) {
                            await this.fetchOptions(filter);
                        } else if (filter.sInputGroup == "slider") {
                            filter.aOptions = [{ value: filter.sName + 'opt' }];
                            filter.selected = [Number(filter.oRange.nMin), Number(filter.oRange.nMax)];
                        }
                        if (filter?.sName?.toLowerCase() == 'gender') {
                            filter.aOptions.forEach(opt => {
                                opt.selected = self.widgetConfig?.gender?.includes(opt.sKey) || false;
                                if (opt.selected) filter.hasDefaultFilter = true;
                            })
                        }
                        if (filter?.sName?.toLowerCase() == 'product') {
                            filter.aOptions.forEach((opt) => {
                                opt.selected = self.widgetConfig?.productFilter == opt.sKey;
                                if (opt.selected) filter.hasDefaultFilter = true;
                            });
                        }
                    }
                    // this.newFiltersData = JSON.parse(JSON.stringify(this.requestParams.oFilterBy));
                    // console.log('getFiltersList -> calling fetch color properties')
                    this.fetchColorProperties();
                }
                
                this.fetchGenderCount();
                this.fetchDynamicProperties();
                if (!this.showFilterSideBar) {
                    setTimeout(() => { this.showApiFields = true; }, 1000);
                }
            })
        },
        async fetchOptions(filter) {
            // console.log('fetchOptions called for', filter)
            let self = this;
            if (filter.sApiUrl.includes('/properties/')) {
                const propertiesResponse = await this.axios.get(`${this.coreApiUrl}${filter.sApiUrl}?iBusinessId=${this.requestParams.iBusinessId}`, this.headers)
                if (propertiesResponse?.data?.data?.aOptions?.length){
                    filter.iPropertyId = propertiesResponse.data.data._id;
                    filter.aOptions = propertiesResponse.data.data.aOptions.filter((opt) => opt.sKey != "UNDEFINED").map(opt => {
                        opt.label = this.displayOptionName(opt, filter.sDisplayNameField);
                        opt.iPropertyId = propertiesResponse.data.data._id;
                        if (this.widgetConfig?.category?.length) {
                            this.widgetConfig.category.filter((category) => {
                                if (category?.iPropertyId === filter.iPropertyId && category?.property?.sPropertyOptionName == opt.sKey) {
                                    opt.selected = true;
                                    filter.setSubProperties = true;
                                    filter.hasDefaultFilter = true;
                                    this.onChangeFilter(true, opt, filter);
                                    this.selectedFilters.push(opt);
                                }
                                return category;
                            });
                        }
                        return opt;
                    });
                }
                if (!this.showFilterSideBar) {
                    this.updatedFilters = false;
                    setTimeout(() => { this.updatedFilters = true; }, 100);
                }
            } else {
                const params = filter.sRequestFormat.split(":");
                this.requestParams.oFilterBy.aCategory = this.aCategoryFilters;
                const finalPayload = { ...this.requestParams, aPropertyName: [params[0].trim()], isFrom: 'assortment'};
                const propertiesResponse = await this.axios.post(`${this.coreApiUrl}${filter.sApiUrl}`, finalPayload, this.headers);
                if (propertiesResponse?.data?.data?.length) {
                    filter.aOptions = propertiesResponse.data.data[0][params[0].trim()].map(opt => {
                        if (filter.sName == 'BRAND' && opt?.sAlias) {
                            opt.label = this.displayOptionName(opt, 'sAlias');
                        } else {
                            opt.label = this.displayOptionName(opt, filter.sDisplayNameField);
                        }
                        return opt;
                    });

                    if (self.selectedFilters && self.selectedFilters.length) {
                        filter.aOptions = filter.aOptions.map(opt => {
                            let index = self.selectedFilters.findIndex(f => f._id == opt._id);
                            if (index > -1) {
                                opt.selected = true;
                                // console.log('fetchOptions else setting hasDefaultFilter = true for', filter.sName)
                                filter.hasDefaultFilter = true;
                                // return self.selectedFilters[index];
                                return opt;
                            }
                            opt.selected = false;
                            return opt;
                        })
                    }
                }
                if (!this.showFilterSideBar) {
                    this.updatedFilters = false;
                    setTimeout(() => { this.updatedFilters = true; }, 100);
                }
            }
        },
        async fetchGenderCount() {
            const apiUrl = "/api/v1/business/products/count-by-static-properties";
            this.requestParams.oFilterBy.aCategory = this.aCategoryFilters;
            const finalPayload = { ...this.requestParams, aPropertyName: ['eGender'], isFrom: "assortment" };
            this.axios.post(`${this.coreApiUrl}${apiUrl}`, finalPayload, this.headers).then((response) => {
                if (response?.data?.data?.length && response.data.data[0].eGender) {
                    response.data.data[0].eGender.forEach((gender) => {
                        this.dynamicPropertyList.push(gender);
                    });
                }
            });
        },
        async fetchDynamicProperties() {
            delete this.requestParams["aPropertyName"];
            // this.requestParams["oFilterBy"]['oStatic'] = {};
            // this.requestParams["oFilterBy"]['aConditionDynamicProperty'] = [];
            this.requestParams.oFilterBy.aCategory = this.aCategoryFilters;
            const finalPayload = { ...this.requestParams, isFrom: "assortment" };
            this.axios.post(`${this.coreApiUrl}/api/v1/business/products/count-by-dynamic-properties`, finalPayload, this.headers).then((response) => {
                if (response?.data?.data?.length) {
                    response.data.data.forEach((details) => {
                        this.dynamicPropertyList.push(details);
                    });
                    const categoryList = this.dynamicPropertyList?.filter((details) => details.sPropertyName === "Category" || details.sPropertyName === "Sub-category");
                    if (categoryList.length) {
                        this.$emit('categoryList', categoryList);
                    }
                }
            });
        },
        fetchColorProperties() {
            const oBody = {
                oFilterBy: { "bColorsProperties": true },
                aProjection: ['oParentField', 'aOptions'],
                iBusinessId: this.requestParams.iBusinessId
            };
            this.axios.post(`${this.coreApiUrl}/api/v1/properties/list-without-settings`, oBody, this.headers).then((result) => {
                if (result?.data?.data?.length) {
                    this.colorPropertiesList = result.data.data;
                    let colorFilterIndex = this.filtersList.findIndex((filter) => filter.sName == "COLOR");
                    if (colorFilterIndex > -1) {
                        this.colorPropertiesList.forEach((property) => {
                            this.filtersList[colorFilterIndex].aSubGroupIds = this.filtersList[colorFilterIndex].aSubGroupIds.map((opt) => {
                                let optList = opt.sName.includes("{or}") ? opt.sName.split("{or}") : [];
                                optList = opt.sName.includes("{and}") ? opt.sName.split("{and}") : optList;
                                if (opt.sName.includes(property?.oParentField?.value) || property?.sName.includes(opt.sName) || (optList.length > 0 && (property?.sName.includes(optList[0].trim()) || property?.sName.includes(optList[1].trim())))) {
                                    opt[property.sName] = property;
                                }
                                opt.sRequestFormat = this.filtersList[colorFilterIndex].sRequestFormat;
                                return opt;
                            });
                        });
                        if (this.widgetConfig?.colors?.length) {
                            // console.log('521 fetchColorProperties -> calling setColorFilters')
                            this.setColorFilters(colorFilterIndex);
                        } else {
                            // console.log('523 emit change from fetchColorProperties')
                            this.$emit('change');
                        }
                    } else {
                        // console.log('525 fetchColorProperties -> calling setColorFilters')
                        this.setColorFilters(colorFilterIndex);
                    }
                } else {
                    // console.log('531 emit change from fetchColorProperties')
                    this.$emit('change');
                }
            });
        },
        setColorFilters(filterIndex) {
            this.validateFilters();
            if (filterIndex == -1) {
                // console.log('emit change from setColorFilters filterIndex == -1')
                this.$emit('change');
                return;
            }
            this.colorPropertiesList.forEach((property) => {
                if (this.widgetConfig.category?.length) {
                    this.widgetConfig.category.forEach((category) => {
                        if (category.sPropertyName == property?.oParentField?.sName && category.value == property?.oParentField?.value) {
                            this.widgetConfig.colors.forEach((color) => {
                                let details = {
                                    iPropertyId: property._id,
                                    iPropertyOptionId: color._id, 
                                    property: color
                                };
                                if (this.filtersList[filterIndex]?.aSubGroupIds?.length > 0) {
                                    this.filtersList[filterIndex].aSubGroupIds = this.filtersList[filterIndex].aSubGroupIds.forEach((opt) => {
                                        if (opt.sName.includes(category.value)) {
                                            let optIndex = opt.aOptions.findIndex((cOpt) => cOpt.sKey == color.sKey);
                                            opt.aOptions[optIndex].selected = true;
                                        }
                                    });
                                } else if (this.filtersList[filterIndex]?.aOptions?.length > 1) {
                                    this.filtersList[filterIndex].aOptions = this.filtersList[filterIndex].aSubGroupIds.map((opt) => {
                                        opt.selected = true;
                                        return opt;
                                    });
                                }
                                let subIndex = -1;
                                let parentPropertyIndex = -1;
                                let mainIndex = this.requestParams.oFilterBy.aCategory?.findIndex((main) => {
                                    subIndex = main.findIndex((subOption) => subOption.iPropertyId == property._id);
                                    parentPropertyIndex = main.findIndex((subOption) => subOption.iPropertyId == category?._id);
                                    return subIndex > -1 || parentPropertyIndex > -1;
                                });
                                if (mainIndex == -1 && subIndex == -1) {
                                    this.requestParams.oFilterBy.aCategory.push(details);
                                } else if (mainIndex > -1) {
                                    // if (subIndex > 0 && typeof (this.requestParams.oFilterBy.aCategory[mainIndex][subIndex].oProperty[color.sKey]) == 'undefined') {
                                    //     let tempArray = JSON.parse(JSON.stringify(this.requestParams.oFilterBy.aCategory[mainIndex]));
                                    //     tempArray[subIndex].oProperty = details.oProperty
                                    //     this.requestParams.oFilterBy.aCategory.push(
                                    //         tempArray
                                    //     );
                                    // } else if ((parentPropertyIndex > -1 && subIndex == -1) || (parentPropertyIndex > -1 && typeof (this.requestParams.oFilterBy.aConditionDynamicProperty[mainIndex][subIndex].oProperty[color.sKey]) == 'undefined')) {
                                    //     this.requestParams.oFilterBy.aCategory[mainIndex].push(details);
                                    // } else if (subIndex == -1 && parentPropertyIndex == -1) {
                                    //     this.requestParams.oFilterBy.aCategory.push([details]);
                                    // }
                                }
                            });
                        }
                    });
                } else {
                    this.widgetConfig.colors.forEach((color) => {
                        let details = {
                            iPropertyId: property._id,
                            iPropertyOptionId: color._id, 
                            property: color
                        };
                        this.requestParams.oFilterBy.aCategory.push(details)
                    });
                }
            });
            // console.log('emit change from setColorFilters')
            this.$emit('change');
        },
        displayOptionName(option, name) {
            let optName = "";
            if (name && name.includes('{lang}') && option[name.replace('{lang}', '')]) {
                optName = option[name.replace('{lang}', '')][this.language] ? option[name.replace('{lang}', '')][this.language] : option[name.replace('{lang}', '')]['en-us']
            } else {
                optName = option[name] || 'Unknown';
            }
            return optName;
        },

        validateFilters() {
            var resArr = [];
            this.requestParams.oFilterBy.aCategory?.filter(function (item) {
                let i = -1;
                // item.filter((res) => {
                //     let propertyKey = Object.keys(res.oProperty);
                //     i = resArr.findIndex(x => {
                //         let details = x.filter((xRes) => (xRes.iPropertyId == res.iPropertyId && xRes.oProperty[propertyKey] == res.oProperty[propertyKey]))
                //         return details.length == x.length;
                //     });
                //     return null;
                // });

                if (i <= -1) {
                    resArr.push(item);
                }
            });
            this.requestParams.oFilterBy.aCategory = resArr;
        },
        jps(obj) {
            return JSON.parse(JSON.stringify(obj || ''));
        },
        onChangeFilter(event, item, filter, bNeedToAdd = false) {
            let bTesting = false;
            const tempFilterName = filter.sName.split('_FN_');
            const filterName = tempFilterName[tempFilterName.length -1 ];
            const optionDetails = {
                iPropertyId: filter.propertyId || filter.iPropertyId,
                sPropertyName: filterName,
                sPropertyOptionName: item?.value || '',
                iPropertyOptionId: item?._id || '',
                bNeedsToAppend: item?.bNeedsToAppend || false
            };
            if (filter.sInputGroup == "slider") {
                if (bTesting) console.log('input group is slider',)
                if (filterName == 'PRICE') {
                    filter.selected = event;
                    if (bTesting) console.log('filter name is price')
                    this.requestParams.oFilterBy.oPriceRange = { 'nMinimum': Number(filter.selected[0]), 'nMaximum': Number(filter.selected[1]) }
                } else {
                    filter.selected = [event[0].replace(/[^0-9_]/g, ''), event[1].replace(/[^0-9_]/g, '')];
                }
            }
            // } else {
            // if (bTesting) console.log('process dynamic filter')
            if (filter && filter.sRequestFormat) {
                if (bTesting) console.log('filter has request format ' + filter.sRequestFormat)
                if (typeof (event) == "boolean") {
                    if (bTesting) console.log('event type is boolean')
                    item.selected = event;
                    if (bTesting) console.log('item.selected', item.selected);
                }
                // this.updatedFilters = false;
                // setTimeout(()=>{this.updatedFilters = true;},10);
                const params = filter.sRequestFormat.split(":");
                let keyValue = params[0].replace(/[^a-zA-Z0-9_]/g, '');
                if (bTesting) console.log({ params, keyValue });
                if (params[0].includes("{")) {
                    if (bTesting) console.log('params[0].includes("{")', params[0])
                    keyValue = item?.iPropertyId ? item.iPropertyId : this.checkProductDetail(filter, item, keyValue);
                    if (keyValue == 0) {
                        if (bTesting) console.log('keyvalue is 0 so returning')
                        return;
                    }
                    // item['_id'] = keyValue;
                    if (bTesting) console.log('assigned keyvalue to item._id')
                }

                let filters = Object.keys(this.requestParams.oFilterBy).findIndex((filter) => filter == params[0].trim());
                if (bTesting) console.log(591, { filters })
                if (params.length > 1 && filter.sInputGroup != "slider") {
                    if (bTesting) console.log(`if params.length > 1 && filter.sInputGroup != "slider"`);
                    let indexValue = params[1].replace(/[^a-zA-Z0-9_]/g, '');
                    if (bTesting) console.log(594, { indexValue }, 'typeof (event)', typeof (event));

                    if ((typeof (event) == "boolean" && event) || filter.sInputGroup == "radio" || (filter.sInputGroup == "checkboxWithColor" && !item.selected) || (this.dudaConfig?.device == "mobile" && filterName == 'CATEGORY')) {
                        if (bTesting) console.log(589);
                        if (filters == -1 && params.length == 2 && params[1].length > indexValue.length) {
                            if (bTesting) console.log(591);
                            this.requestParams.oFilterBy[keyValue] = [];
                        }
                        if (params[1].length > indexValue.length && params.length == 2) {
                            if (bTesting) console.log(595);
                            if(!this.requestParams.oFilterBy[keyValue].includes(item[indexValue])){
                                this.requestParams.oFilterBy[keyValue].push(item[indexValue]);
                            }
                            
                            // Handle gender filters additional properties
                            if(keyValue == 'eGender'){
                                if (['male', 'female'].includes(item[indexValue]) && !this.requestParams.oFilterBy['eGender'].includes('male-female')) {
                                    this.requestParams.oFilterBy['eGender'].push('male-female');
                                } else if (['boy', 'girl'].includes(item[indexValue]) && !this.requestParams.oFilterBy['eGender'].includes('boy-girl')) {
                                    this.requestParams.oFilterBy['eGender'].push('boy-girl');
                                } else if (['boy-teenager', 'girl-teenager'].includes(item[indexValue]) && !this.requestParams.oFilterBy['eGender'].includes('boy-girl-teenager')) {
                                    this.requestParams.oFilterBy['eGender'].push('boy-girl-teenager');
                                }
                            }
                        } else if (params.length == 2) {
                            if (bTesting) console.log(598);
                            this.requestParams.oFilterBy[keyValue] = item[indexValue];
                        } else if (params.length == 3) {
                            if(!this.requestParams.oFilterBy.aCategory){
                                this.requestParams.oFilterBy.aCategory = [];
                            }
                            if (bTesting) console.log(601);
                            let key1 = params[1].replace(/[^a-zA-Z0-9_]/g, '');
                            let value1 = params[2].replace(/[^a-zA-Z0-9_]/g, '');
                            if (filter.sInputGroup == "checkboxWithColor") {
                                if (bTesting) console.log(605);
                                item.selected = true;
                                let subIndex = -1;
                                let parentPropertyIndex = -1;
                                let mainIndex = -1
                                let parentColorProperty = this.colorPropertiesList.filter((property) => {
                                    let tempSubIndex = -1;
                                    let tempParentPropertyIndex = -1;
                                    keyValue = property._id;
                                    let tempIndex = this.requestParams.oFilterBy.aCategory?.findIndex((main) => {
                                        tempSubIndex = main.findIndex((subOption) => subOption.iPropertyId == keyValue);
                                        tempParentPropertyIndex = main.findIndex((subOption) => { let parentKeys = Object.keys(subOption?.oProperty); return subOption?.oProperty[parentKeys[0]] === property?.oParentField?.value; });
                                        return tempSubIndex > -1 || tempParentPropertyIndex > -1;
                                    });
                                    if (tempIndex > -1) {
                                        mainIndex = tempIndex;
                                        subIndex = tempSubIndex;
                                        parentPropertyIndex = tempParentPropertyIndex;
                                    }
                                    return tempIndex > -1;
                                });
                                if (parentColorProperty?.length > 0) {
                                    keyValue = parentColorProperty[0]._id;
                                    let details = {
                                        iPropertyId: keyValue,
                                        iPropertyOptionId: optionDetails?.iPropertyOptionId, 
                                        property: optionDetails
                                    };
                                    if (Object.keys(filter).includes(parentColorProperty[0].sName) && mainIndex > -1 && bNeedToAdd) {
                                        details.oProperty[item[key1]] = item[value1];
                                        if (subIndex > 0) {
                                            let tempArray = JSON.parse(JSON.stringify(this.requestParams.oFilterBy.aCategory[mainIndex]));
                                            tempArray[subIndex].oProperty = details.oProperty
                                            this.requestParams.oFilterBy.aCategory.push(
                                                tempArray
                                            );
                                        } else if (parentPropertyIndex > -1) {
                                            this.requestParams.oFilterBy.aCategory[mainIndex].push(details);
                                        } else {
                                            this.requestParams.oFilterBy.aCategory.push(details);
                                        }
                                    }
                                } else if (mainIndex == -1 && bNeedToAdd) {
                                    this.colorPropertiesList.filter((property) => {
                                        keyValue = property[0]?._id;
                                        let details = {
                                            iPropertyId: keyValue,
                                            iPropertyOptionId: optionDetails?.iPropertyOptionId, 
                                            property: optionDetails
                                        };
                                        if (Object.keys(filter).includes(property.sName)) {
                                            details.oProperty[item[key1]] = item[value1];
                                            this.requestParams.oFilterBy.aCategory.push(details);
                                        }
                                    });
                                }
                            } else {
                                this.setCatergoryFilter(event, filter, optionDetails);
                            }
                        }
                    } else if (typeof (event) == "boolean" || (filter.sInputGroup == "checkboxWithColor" && item.selected)) {
                        // console.log(881, params)
                        if (params.length == 2 && params[1].length > indexValue.length) {
                            let index = (this.requestParams.oFilterBy[keyValue]).indexOf(item[indexValue]);
                            this.requestParams.oFilterBy[keyValue].splice(index, 1);
                            
                            // Handle gender filters additional properties
                            if(keyValue == 'eGender'){
                                if (['male', 'female'].includes(item[indexValue]) && !this.requestParams.oFilterBy['eGender'].includes('male') && !this.requestParams.oFilterBy['eGender'].includes('female')) {
                                    let index = this.requestParams.oFilterBy['eGender'].indexOf('male-female');
                                    this.requestParams.oFilterBy['eGender'].splice(index, 1);
                                } else if (['boy', 'girl'].includes(item[indexValue]) && !this.requestParams.oFilterBy['eGender'].includes('boy') && !this.requestParams.oFilterBy['eGender'].includes('boy')) {
                                    let index = this.requestParams.oFilterBy['eGender'].indexOf('boy-girl');
                                    this.requestParams.oFilterBy['eGender'].splice(index, 1);
                                } else if (['boy-teenager', 'girl-teenager'].includes(item[indexValue]) && !this.requestParams.oFilterBy['eGender'].includes('boy-teenager') && !this.requestParams.oFilterBy['eGender'].includes('girl-teenager')) {
                                    let index = this.requestParams.oFilterBy['eGender'].indexOf('boy-girl-teenager');
                                    this.requestParams.oFilterBy['eGender'].splice(index, 1);
                                }
                            }

                            if (this.requestParams.oFilterBy[keyValue]?.length == 0) {
                                delete this.requestParams.oFilterBy[keyValue];
                            }
                        } else if (params.length == 3) {
                            let key1 = params[1].replace(/[^a-zA-Z0-9_]/g, '');
                            let value1 = params[2].replace(/[^a-zA-Z0-9_]/g, '');

                            if (filter.sInputGroup == "checkboxWithColor") {
                                item.selected = false;
                                this.colorPropertiesList.filter((property) => {
                                    keyValue = property._id;
                                    let subIndex = -1;
                                    let indices = this.requestParams.oFilterBy.aCategory.map((main, i) => {
                                        let subIndexPropety = main.findIndex((subOption) => subOption.iPropertyId == keyValue);
                                        let parentPropertyIndex = main.findIndex((subOption) => { let parentKeys = Object.keys(subOption?.property); return subOption?.property[parentKeys[0]] === property?.oParentField?.value; });
                                        return parentPropertyIndex > -1 || subIndexPropety > -1 ? i : '';
                                    }).filter(String);
                                    let mainIndex = this.requestParams.oFilterBy.aCategory.findIndex((main) => {
                                        subIndex = main.findIndex((subOption) => subOption.iPropertyId == keyValue && subOption.oProperty[item[key1]] === item[value1])
                                        return subIndex > -1
                                    });
                                    if (mainIndex > -1 && subIndex > -1) {
                                        if (subIndex == 0) {
                                            for (let i = indices.length - 1; i >= 0; i--) {
                                                this.requestParams.oFilterBy.aCategory.splice(indices[i], 1);
                                            }
                                        } else if (subIndex == 1) {
                                            let tempArray = JSON.parse(JSON.stringify(this.requestParams.oFilterBy.aCategory[mainIndex][0]));
                                            let removedCount = indices.length;
                                            for (let i = indices.length - 1; i >= 0; i--) {
                                                if (this.requestParams.oFilterBy.aCategory[indices[i]][subIndex].oProperty[item[key1]]) {
                                                    this.requestParams.oFilterBy.aCategory.splice(indices[i], 1);
                                                    --removedCount;
                                                }
                                            }
                                            if (removedCount == 0 && tempArray?.iPropertyId) {
                                                this.requestParams.oFilterBy.aCategory.push([tempArray]);
                                            }
                                        } else if (indices.length > 0) {
                                            this.requestParams.oFilterBy.aCategory.splice(mainIndex, 1);
                                        }
                                    }
                                });
                            } else {
                                this.setCatergoryFilter(event, filter, optionDetails);
                            }
                        } else {
                            delete this.requestParams.oFilterBy[keyValue];
                        }
                    }
                    item['filter'] = filterName;
                } else if (filter.sInputGroup == "slider") {
                    if (bTesting) console.log(`else if filter.sInputGroup == "slider"`)
                    
                    // console.log(819)
                    if (filterName == 'PRICE') {
                        filter.selected = event;
                        if (bTesting) console.log('filter name is PRICE')
                        this.requestParams.oFilterBy.oPriceRange = { 'nMinimum': Number(filter.selected[0]), 'nMaximum': Number(filter.selected[1]) }
                    } else {
                        filter.selected = [event[0].replace(/[^0-9_]/g, ''), event[1].replace(/[^0-9_]/g, '')];
                    }
                    // console.log(822, this.requestParams.oFilterBy);
                    // item['filter'] = filter.sName;
                } else if (filter.sInputGroup == "checkboxWithColor") {
                    if (bTesting) console.log(`filter.sInputGroup == "checkboxWithColor"`)
                    item.selected = !item.selected;
                    item['filter'] = event.sName;
                } else if (params.length == 1) {
                    if (bTesting) console.log(`params.length == 1`)
                    if (event) {
                        if (bTesting) console.log(`if event`)
                        let singleFilter = this.constSingleKeyFilter.filter((singleKey) => singleKey.key == keyValue.charAt(0));
                        if (typeof (this.requestParams.oFilterBy.$or) == 'undefined') {
                            this.requestParams.oFilterBy.$or = [];
                        }
                        let objectValue = {}
                        if (singleFilter?.length) {
                            if (singleFilter[0].key == "a") {
                                singleFilter[0].value.$expr.$gt[0].$size = "$" + keyValue;
                            }
                            objectValue[keyValue] = singleFilter[0].value;
                            this.requestParams.oFilterBy.$or.push(objectValue);
                        } else {
                            objectValue[keyValue] = true;
                            this.requestParams.oFilterBy.$or.push(objectValue);
                        }
                    } else {
                        if (bTesting) console.log(`868 else part`)
                        let index = this.requestParams.oFilterBy.$or.findIndex((fIndex) => Object.keys(fIndex).includes(keyValue));
                        this.requestParams.oFilterBy.$or.splice(index, 1);
                        if (this.requestParams.oFilterBy.$or?.length == 0) {
                            delete this.requestParams.oFilterBy.$or;
                        }
                    }
                }
                // if ((typeof (event) == "string" && event.trim() != '') || (typeof (event) == "object" && event.length) || (filter.sInputGroup == "checkboxWithColor" && item.selected)) {
                //     this.selectedFilters.push(item);
                // } else {
                //     this.selectedFilters = this.selectedFilters.filter(f => f.value != item.value);
                // }
                // console.log('onChangeFilter -> setSubFilters')
                this.setSubFilters({ flag: event, filterDetails: filter, filter: item });
            }
            // }
        },
        setSubFilters(event) {
            // console.log('setSubFilters called', this.jps(this.categoryFiltersList))
            // const filterParentField = event?.filterDetails?.oParentField;
            const tempFilterName = event.filterDetails.sName.split('_FN_');
            const filterName = tempFilterName[tempFilterName.length -1 ];
            const existSubFilter = this.categoryFiltersList.filter((details) => {
                return (details.property.oParentField.sName.toUpperCase() == filterName.toUpperCase() &&
                    details.property.oParentField.value == event.filter.value);
            });
            // console.log('existSubFilter', this.jps(existSubFilter))
            // let filterIndex = this.filtersList.findIndex((filter) => filter.sName == event.filterDetails.sName);
            
            if (event.flag && existSubFilter.length) {
                
                existSubFilter.forEach(async subFilter => {
                    const subFilterDetails = await this.getChildFilterDetails(subFilter, event);
                    // if (subFilter.property.sDataBaseFieldName.includes(`${event.filter.sKey.toLowerCase()}.type`)) {
                        // filterIndex++;
                        // this.filtersList.splice(filterIndex, 0, subFilterDetails);
                    //} else {
                    //}

                    const subCategoriesList = this.widgetConfig?.subCategory?.filter((subCategory) => subCategory?.iPropertyId === subFilterDetails.propertyId) || [];
                    const grandCategoriesList = this.widgetConfig?.aGrandCategory?.filter((grandCategory) => grandCategory?.iPropertyId === subFilterDetails.propertyId) || [];
                    const updatedOptions = subFilterDetails.aOptions.map((opt) => {
                        if (subFilterDetails.setSubProperties && subCategoriesList.length) {
                            subCategoriesList.map((subCategory) => {
                                if (subCategory?.iPropertyId === subFilterDetails.propertyId && subCategory?.property?.sPropertyOptionName == opt.sKey) {
                                    opt.selected = true;
                                    subFilterDetails.hasDefaultFilter = true;
                                    subFilterDetails.setSubProperties = true;
                                    // console.log('setSubFilters ->  on change filter, opt', opt)
                                    this.onChangeFilter(true, opt, subFilterDetails);
                                }
                                return subCategory;
                            });
                        }
                        if (subFilterDetails.setSubProperties && grandCategoriesList.length) {
                            grandCategoriesList.map((grandCategory) => {
                                if (grandCategory?.iPropertyId === subFilterDetails.propertyId && grandCategory?.property?.sPropertyOptionName == opt.sKey) {
                                    opt.selected = true;
                                    subFilterDetails.hasDefaultFilter = true;
                                    this.onChangeFilter(true, opt, subFilterDetails);
                                }
                                return grandCategory;
                            });
                        }
                        if(this.oldFiltersList.length > 0){
                            const filteredCategory = this.oldFiltersList.find((category) => category.iPropertyId == subFilterDetails.mainParentPropertyId || category.iPropertyId == subFilterDetails.parentPropertyId);
                            const filteredSubCategory = filteredCategory.aSubCategory?.find((category) => category.iPropertyId == subFilterDetails.parentPropertyId || category.iPropertyId == subFilterDetails.propertyId);
                            if(filteredSubCategory){
                                if(filteredSubCategory.aPropertyOption?.length > 0){
                                    filteredSubCategory.aPropertyOption.forEach(
                                        (subCategoryOpt)=> {
                                            if(subFilterDetails.propertyId === filteredSubCategory.iPropertyId && opt._id == subCategoryOpt.iPropertyOptionId){
                                                opt.selected  = true;
                                                subFilterDetails.setSubProperties = true;
                                                this.onChangeFilter(true, opt, subFilterDetails);
                                            }
                                            if(subCategoryOpt.aGrandCategory?.length > 0){
                                                subCategoryOpt.aGrandCategory.forEach(
                                                    (grandCategory) => {
                                                        grandCategory.aPropertyOption.forEach(
                                                            (grandCategoryOpt)=> {
                                                                if(subFilterDetails.propertyId === grandCategory.iPropertyId && opt._id == grandCategoryOpt.iPropertyOptionId){
                                                                    opt.selected  = true;
                                                                    subFilterDetails.setSubProperties = true;
                                                                    this.onChangeFilter(true, opt, subFilterDetails);
                                                                }
                                                            }
                                                        );
                                                    }
                                                );
                                            }
                                        }
                                    );
                                }
                            }
                        }
                        return opt;
                    });

                    subFilterDetails.aOptions = this.jps(updatedOptions);
                    const filterIndex = this.filtersList.findIndex((filter) => filter.sName == subFilterDetails.sName);
                    if(filterIndex > -1){
                        this.filtersList[filterIndex] = subFilterDetails;
                    } else {
                        this.filtersList.push(subFilterDetails);
                    }
                    
                    
                });
            } else if (existSubFilter.length) {
                const categoryLevel = event.filterDetails?.categoryLevel == "subCategory" ? "grandCategory" : "subCategory";
                existSubFilter.forEach(subFilter => {
                    this.filtersList = this.filtersList.filter((filter) => {
                        const result = (filter.propertyId != subFilter.property._id && (!filter?.parentPropertyId || (filter?.parentPropertyId != subFilter.property._id)))
                        || (!filter.categoryLevel || filter.categoryLevel != categoryLevel);
                        return result;
                    });
                });
            } else if (event.filter) {
                event.filter.setSubProperties = false;
                this.filtersList.push({});
                setTimeout(() => { this.filtersList.filter((filter) => typeof (filter._id) != 'undefined'); }, 10);
            }
        },
        async getChildFilterDetails(childFilter, filterEvent){
            const subFilterDetails = this.jps(filterEvent.filterDetails);
            subFilterDetails.hasDefaultFilter = false;
            subFilterDetails.sName = `${filterEvent.filter.value}_FN_${childFilter.property.sName}`;
            subFilterDetails.bIsDynamic = false;
            if (subFilterDetails.parentPropertyId) {
                subFilterDetails.mainParentPropertyId = subFilterDetails.parentPropertyId;
                subFilterDetails.mainParentValue = subFilterDetails.parentValue;
                subFilterDetails.categoryLevel = "grandCategory";
            } else {
                subFilterDetails.categoryLevel = "subCategory";
            }
            subFilterDetails.parentPropertyId = subFilterDetails.propertyId || subFilterDetails.iPropertyId;
            subFilterDetails.parentValue = { ...filterEvent.filter, hasDefaultFilter: filterEvent.filterDetails.hasDefaultFilter};
            subFilterDetails.propertyId = childFilter.property._id;
            subFilterDetails.selected = "";
            subFilterDetails.oParentField = childFilter?.property?.oParentField?.sName != "" ? childFilter?.property?.oParentField : undefined;
            
            
            const bNeedsToAppend = childFilter.property.aOptions.filter((opt) => opt.sKey != "UNDEFINED").some(opt => {
                const oFoundAppendArticle = this.categoryFiltersList?.find((el) =>  el?.property?.oParentField?.sName === childFilter?.property?.sName && el?.property?.oParentField?.value === opt?.value);
                return oFoundAppendArticle?.property?.bNeedsToAppend || false;
            });
            subFilterDetails.aOptions = this.jps(childFilter.property.aOptions.filter((opt) => opt.sKey != "UNDEFINED").map(opt => {
                opt['label'] = this.displayOptionName(opt, subFilterDetails.sDisplayNameField);
                opt.selected = false;
                opt.iPropertyId = childFilter.property._id;
                filterEvent.filter.setSubProperties = false;
                opt.bNeedsToAppend = bNeedsToAppend;
                return opt;
            }));

            return subFilterDetails;
        },
        async fetchCategoryPropertyList() {
            let requestParams = {
                oFilterBy: { sSectionName: "product-filter", eRole: "retailer", sFunctionName: "update-business-product" },
                iBusinessId: this.requestParams.iBusinessId
            };
            this.axios.post(`${this.coreApiUrl}/api/v1/property/settings/list`, requestParams, this.headers).then(
                (result) => {
                    if (result?.data?.data?.length) {
                        this.categoryFiltersList = result.data.data;
                    }
                });
        },
        checkProductDetail(filterDetails, option, isFor) {
            // console.log({ filterDetails, option, isFor }, this.dynamicPropertyList)
            let count = 0;
            if (this.dynamicPropertyList && this.dynamicPropertyList?.length > 0) {
                this.dynamicPropertyList.filter((filter) => {
                    if (filter.sPropertyOptionValue == option?.value && isFor == 'count' && filterDetails?.sName?.toLowerCase() == filter?.sPropertyName?.toLowerCase()) {
                        count = filter.count;
                    } else if (filter.sPropertyOptionValue == option?.value && isFor == '_id' && filterDetails?.sName?.toLowerCase() == filter?.sPropertyName?.toLowerCase()) {
                        count = filter.iPropertyId;
                    } else if (option && option[isFor]) {
                        count = option[isFor];
                    }
                });
            }
            return count;
        },
        async generateCategoryPayload(category){
            let categoryArray = [];
            let categoryObject = {
                iPropertyId: category.iPropertyId,
                oProperty: {}
            };
            const optionName = category.sPropertyOptionName;
            categoryObject.oProperty[optionName] = optionName;

            if(category.aSubCategory?.length > 0){
                category.aSubCategory?.forEach(
                    (subCategory) => {
                        subCategory.aPropertyOption?.forEach(
                            (subCategoryOption) => {
                                let subCategoryObject = {
                                    iPropertyId: subCategory.iPropertyId,
                                    oProperty: {}
                                };
                                const optionName = subCategoryOption.sPropertyOptionName;
                                subCategoryObject.oProperty[optionName] = optionName;
                                if(subCategoryOption.aGrandCategory?.length > 0){
                                    subCategory.aGrandCategory?.forEach(
                                        (grandCategory) => {
                                            let grandCategoryObject = {
                                                iPropertyId: grandCategory.iPropertyId,
                                                oProperty: {}
                                            };
                                            grandCategory.aPropertyOption?.forEach(
                                                (grandCategoryOption) => {
                                                    const optionName = grandCategoryOption.sPropertyOptionName;
                                                    grandCategoryObject.oProperty[optionName] = optionName;
                                                    categoryArray.push([categoryObject, subCategoryObject, grandCategoryObject]);
                                            })
                                        }
                                    );
                                } else {
                                    categoryArray.push([categoryObject, subCategoryObject]);
                                }
                            }
                        )
                        
                    }
                )
            } else {
                categoryArray.push([categoryObject]);
            }
            return categoryArray;
        },
        setCatergoryFilter(value, filter, optionDetails) {
            if(this.aCategoryFilters.length > 0 && filter?.parentValue?.iPropertyId) {
                this.aCategoryFilters = this.aCategoryFilters.map(
                    (category) => {
                        const parentDetails = filter?.parentValue;
                        const propertyOption = {
                            iPropertyOptionId: optionDetails.iPropertyOptionId,
                            sPropertyOptionName: optionDetails.sPropertyOptionName,
                            bNeedsToAppend: optionDetails.bNeedsToAppend
                        };
                        const categoryDetails = {
                            iPropertyId: optionDetails.iPropertyId,
                            sPropertyName: optionDetails.sPropertyName,
                            aPropertyOption : [
                                propertyOption
                            ]
                        };

                        const existSubFilterList = this.categoryFiltersList.filter((details) => {
                            return ( details.property.oParentField.sName.toUpperCase() == parentDetails?.filter?.toUpperCase() &&
                                details.property.oParentField.value == parentDetails?.value &&
                                !details.property.aOptions.some((option) => option.bNeedsToAppend));
                        });
                        
                        // check parent category of selected subcategory
                        const isParentCategory = (category.iPropertyId == parentDetails?.iPropertyId && category?.iPropertyOptionId === parentDetails?._id);
                        if(category.aSubCategory?.length > 0){
                            // get existing selected subcategory 
                            const subCategoryIndex = category.aSubCategory.findIndex( (subCategory) => subCategory.iPropertyId == categoryDetails.iPropertyId);
                            const appendSubCategories = category.aSubCategory.find( (subCategory) => subCategory.aPropertyOption.findIndex((property) => property.bNeedsToAppend) > -1 );
                            const existGrandCategories = category.aSubCategory.filter((subCategory) => existSubFilterList.findIndex( (filter) => filter.property._id == subCategory.iPropertyId) > -1 );
                            if(value && isParentCategory && (!appendSubCategories || propertyOption.bNeedsToAppend)){ // select subcategory
                                if(subCategoryIndex > -1){
                                    if(propertyOption.bNeedsToAppend && appendSubCategories.iPropertyId == category.aSubCategory[subCategoryIndex].iPropertyId){
                                        const refrenceOption = category.aSubCategory[subCategoryIndex].aPropertyOption.find((property) => property.bNeedsToAppend);
                                        propertyOption['aGrandCategory'] = refrenceOption.aGrandCategory?.length ? this.jps(refrenceOption.aGrandCategory) : [];
                                    }
                                    category.aSubCategory[subCategoryIndex].aPropertyOption.push(propertyOption);
                                } else {
                                    if(propertyOption.bNeedsToAppend && existGrandCategories.length > 0){
                                        categoryDetails.aPropertyOption[0].aGrandCategory = this.jps(existGrandCategories);
                                        category.aSubCategory.push(categoryDetails);
                                        category.aSubCategory = category.aSubCategory.filter((element) => existGrandCategories.findIndex((existCategory) => existCategory.iPropertyId != element.iPropertyId) > -1)
                                    } else {
                                        category.aSubCategory.push(categoryDetails);
                                    }
                                }
                            } else if(!value && isParentCategory && (!appendSubCategories || propertyOption.bNeedsToAppend )){ // unselect subcategory
                                const subCategoryDetails = this.jps(category.aSubCategory[subCategoryIndex]);
                                category.aSubCategory[subCategoryIndex].aPropertyOption = category.aSubCategory[subCategoryIndex].aPropertyOption.filter(
                                    subCategory => subCategory.iPropertyOptionId != propertyOption.iPropertyOptionId
                                );
                                if(category.aSubCategory[subCategoryIndex].aPropertyOption.length == 0 ){
                                    category.aSubCategory.splice(subCategoryIndex, 1);
                                    if(subCategoryDetails.aPropertyOption[0].aGrandCategory?.length && category.aSubCategory.length == 0 ){
                                        category.aSubCategory = subCategoryDetails.aPropertyOption[0].aGrandCategory
                                            .filter((grandCategory) => existSubFilterList.findIndex((existFilter) => existFilter.property._id == grandCategory.iPropertyId) > -1);
                                    }
                                }
                            } else { // check for grand category
                                category.aSubCategory = category.aSubCategory.map(
                                    (subCategory) => {
                                        if(appendSubCategories && appendSubCategories.iPropertyId != parentDetails.iPropertyId){
                                            subCategory.aPropertyOption.forEach((option, index) => {
                                                // get existing selected subcategory 
                                                const propertyIndex = option.bNeedsToAppend ? index : -1;
                                                // check parent subcategory of selected grand category
                                                const isParentSubCategory = (subCategory.iPropertyId == appendSubCategories.iPropertyId && propertyIndex > -1 );
                                                if(isParentSubCategory && propertyIndex > -1){
                                                    let grandCategory = this.jps(subCategory.aPropertyOption[propertyIndex].aGrandCategory);
                                                    grandCategory = this.setGrandCategories(value, grandCategory, categoryDetails, propertyOption);
                                                    if(grandCategory.length > 0){ // update grand category value
                                                        subCategory.aPropertyOption[propertyIndex].aGrandCategory = this.jps(grandCategory);
                                                    } else { // remove grand category from payload
                                                        delete subCategory.aPropertyOption[propertyIndex].aGrandCategory;
                                                    }
                                                }
                                            })
                                        } else {
                                            // get existing selected subcategory 
                                            const propertyIndex = subCategory.aPropertyOption.findIndex((option) => option.iPropertyOptionId == parentDetails?._id )
                                            // check parent subcategory of selected grand category
                                            const isParentSubCategory = (subCategory.iPropertyId == parentDetails?.iPropertyId && propertyIndex > -1 );
                                            if(isParentSubCategory){
                                                let grandCategory = this.jps(subCategory.aPropertyOption[propertyIndex].aGrandCategory);
                                                grandCategory = this.setGrandCategories(value, grandCategory, categoryDetails, propertyOption);
                                                if(grandCategory.length > 0){ // update grand category value
                                                    subCategory.aPropertyOption[propertyIndex].aGrandCategory = grandCategory;
                                                } else { // remove grand category from payload
                                                    delete subCategory.aPropertyOption[propertyIndex].aGrandCategory;
                                                }
                                            } else {
                                                const subCategoryIndex = category.aSubCategory.findIndex( (subCategory) => subCategory.iPropertyId == categoryDetails.iPropertyId);
                                                if(value && isParentCategory ){ // select subcategory
                                                    if(subCategoryIndex > -1){
                                                        category.aSubCategory[subCategoryIndex].aPropertyOption.push(propertyOption);
                                                    } else {
                                                        category.aSubCategory.push(categoryDetails);
                                                    }
                                                } else if(!value && isParentCategory){ // unselect subcategory
                                                    category.aSubCategory[subCategoryIndex].aPropertyOption = category.aSubCategory[subCategoryIndex].aPropertyOption.filter(
                                                        subCategory => subCategory.iPropertyOptionId != propertyOption.iPropertyOptionId
                                                    );
                                                    if(category.aSubCategory[subCategoryIndex].aPropertyOption.length == 0 ){
                                                        category.aSubCategory.splice(subCategoryIndex, 1);
                                                    }
                                                }
                                            }
                                        }
                                        return subCategory;
                                    }
                                )
                            }
                        } else if(isParentCategory){  // Add new subcategory  
                            category.aSubCategory = [ categoryDetails ];
                        }
                        return category;
                    }
                );
            } else if(this.aCategoryFilters.length > 0 && !value){ // unselect category
                this.aCategoryFilters = this.aCategoryFilters.filter(
                    category => category.iPropertyOptionId != optionDetails.iPropertyOptionId
                );
            } else if(
                optionDetails && value && 
                this.aCategoryFilters.findIndex((category) => category.iPropertyId == optionDetails.iPropertyId && category.iPropertyOptionId == optionDetails.iPropertyOptionId) == -1
            ){ // add new selected category
                this.aCategoryFilters.push(optionDetails);
            }
            

        },
        setGrandCategories(value, grandCategory, categoryDetails, propertyOption){
            if(grandCategory?.length > 0){
                // get existing selected grand category
                const grandCategoryIndex = grandCategory.findIndex( (grandCategory) => grandCategory.iPropertyId == categoryDetails.iPropertyId);
                if(value){ // select grand category
                    if(grandCategoryIndex > -1){
                        grandCategory[grandCategoryIndex].aPropertyOption.push(propertyOption);
                    } else {
                        grandCategory.push(categoryDetails);
                    }
                } else { // unselect grand category
                    grandCategory[grandCategoryIndex].aPropertyOption = grandCategory[grandCategoryIndex].aPropertyOption.filter(
                        grandCategory => grandCategory.iPropertyOptionId != propertyOption.iPropertyOptionId
                    );
                    if(grandCategory[grandCategoryIndex].aPropertyOption == 0 ){
                        grandCategory.splice(grandCategoryIndex, 1);
                    }
                }
            } else if(!grandCategory?.length ) { // Add new grand category 
                grandCategory = [ categoryDetails ];
            }
            return grandCategory;
        }
    }
});