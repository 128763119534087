import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueTranslate from 'vue-translate-plugin';
import VueCookies from 'vue-cookies';

import './assets/style/index.css'
import "@storefront-ui/vue/styles.scss";
Vue.use(VueAxios, axios)
Vue.config.productionTip = false
Vue.use(VueTranslate);
Vue.use(VueCookies, { expires: '1d'})

var app;

export function init({ containerId, container, props }) {
  const parent = container || document.getElementById(containerId);
  axios.defaults.headers.common['organization-id'] = props.widgetConfig?.org || 'Prismanote2';
  // axios.defaults.headers.common['organization-id'] = props.widgetConfig?.org || 'Shoptions';
  // axios.defaults.headers.common['ngrok-skip-browser-warning'] = true;
  app = new Vue({
    render: h => h(App, { props: props }),
  }).$mount()

  parent.appendChild(app.$el);
}

export function clean() {
  app.$destroy();
}

export function setFiltersData() {
  console.log(app);
}
if (process.env.NODE_ENV === "development") {
  init({
    containerId: 'app',
    props: {
      dudaConfig: {
        headline: 'Apps are Awesome',
        element: 'h1'
      }
    }
  });
}
